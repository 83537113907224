import { Component } from '@angular/core';

@Component({
  selector: 'mac-modal-title',
  template: `<div class="modal-title"><ng-content></ng-content></div>`,
  styles: [
    `
      .modal-title {
        height: 2rem;
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0.5rem 1rem;
      }
    `,
  ],
})
export class ModalTitleComponent {}
