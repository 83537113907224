/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
import 'froala-editor/js/plugins.pkgd.min';

// @ts-ignore
import FroalaEditor from 'froala-editor';
import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { CommunicationDefinitionResponse } from 'projects/shared/services/communications/models/communication-definition.response';
import { environment } from 'projects/shared/environments/environment';
import { FroalaEditorDirective } from 'angular-froala-wysiwyg';

interface OptionConfig {
  [key: string]: string
}

interface FroalaControlsObject {
  destroy: () => void;
  initialize: () => void;
  getEditor: Function;
}

@Component({
  selector: 'app-communication-form',
  templateUrl: './communication-form.component.html',
  styleUrls: ['./communication-form.component.scss']
})
export class CommunicationFormComponent implements OnChanges, AfterViewInit {
  @Input() subjectFormControl: FormControl;
  @Input() bodyFormControl: FormControl;
  @Input() communicationDefinition: CommunicationDefinitionResponse;
  @Input() culture: string;
  @Input() width?: number;
  @Input() height?: number;
  froalaOptions: Object | null;
  initControls: FroalaControlsObject;

  ngAfterViewInit(): void {
    this.buildFroalaEditor();
  }

  ngOnChanges(): void {
    // Reinitializing Froala for the new parameters to be refreshed
    this.initControls?.destroy();
    this.buildFroalaEditor();
    this.initControls?.initialize();
  }

  froalaInit(initControls: Object) {
    this.initControls = <FroalaControlsObject>initControls;
    this.initControls.initialize();
  }

  addParameter(control: AbstractControl, parameter: string): void {
    const subject = `${control.value} %%${parameter}%%`;
    control.setValue(subject);
    control.markAsDirty();
    control.updateValueAndValidity();
  }

  private mapAvailableParametersToOptionConfig(): OptionConfig {
    const optionConfig: OptionConfig = {};
    this.communicationDefinition.availableParameters.forEach((property, index) => (optionConfig[property.code] = this.communicationDefinition.availableParameters[index].code));
    return optionConfig;
  }

  private buildFroalaEditor(): void {
    const self = this;
    FroalaEditor.DefineIcon('parameters', { NAME: '%', template: 'text' });
    FroalaEditor.RegisterCommand('parameters', {
      title: 'Available parameters',
      type: 'dropdown',
      icon: 'parameters',
      focus: false,
      undo: true,
      refreshAfterCallback: false,
      options: self.mapAvailableParametersToOptionConfig(),
      callback: function (cmd: any, val: string) {
        this.html.insert(` %%${val}%%`);
        this.undo.saveStep();
      }
    });

    this.froalaOptions = {
      key: environment.froalaEditorKey,
      fullPage: true,
      useClasses: false,
      iframeStyleFiles: ['/assets/styles/froala-custom.css'],
      charCounterCount: true,
      heightMin: 300,
      heightMax: 500,
      height: self.height ?? 'auto',
      width: self.width ?? 'auto',
      imageUploadRemoteUrls: false,
      htmlAllowedTags: ['.*'],
      htmlUntouched: true,
      tableStyles: {
        'fr-dashed-borders': 'Dashed Borders',
        'fr-alternate-rows': 'Alternate Rows',
        noborder: 'No borders'
      },
      tableCellStyles: {
        'fr-highlighted': 'Highlighted',
        'fr-thick': 'Thick',
        noborder: 'No borders'
      },
      toolbarButtons: [
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        '|',
        'fontFamily',
        'fontSize',
        'color',
        'inlineStyle',
        'inlineClass',
        'clearFormatting',
        '|',
        'paragraphFormat',
        'lineHeight',
        'paragraphStyle',
        'align',
        'formatOL',
        'formatUL',
        'outdent',
        'indent',
        'quote',
        'specialCharacters',
        '-',
        'insertLink',
        'insertImage',
        'insertTable',
        'parameters',
        '|',
        'insertHR',
        'selectAll',
        'print',
        'html',
        'fullscreen',
        '|',
        'undo',
        'redo'
      ],
      quickInsertButtons: ['table', 'ol', 'ul', 'hr'],
      imageInsertButtons: ['imageByURL'],
      events: {
        // Need to manually set and get body content from Froala as the formControl binding is broken after reinitializing Froala
        'contentChanged': () => {
          // Manually set body formcontrol after content has changed in froala editor
          const value = this.initControls.getEditor().html.get();
          this.bodyFormControl.setValue(value);
          this.bodyFormControl.markAsDirty();
        },
        'initialized': function(e: FroalaEditorDirective) {
          // Manually set body content into Froala
          e.writeValue(self.bodyFormControl.value);
        },
      }
    };
  }
}
