import { HttpErrorResponse } from "@angular/common/http"

export const getErrorMessage = (error: HttpErrorResponse): string => {
  if (error.status === 404) {
    return error.error?.title || error.error || error.message;
  }

  return error?.error?.message || error?.error || error;
};

export const getErrorKeys = (error: HttpErrorResponse): string[] => {
  return error?.error?.errors || [];
}

export const createErrorKey = (key?: string | undefined): string => `Shared.Validation.Error.${key || ''}`;
