<nz-form-item>
  <nz-form-control nzHasFeedback>
    <div class="imgButtonContainer">
      <img nz-image [width]="fixedWidth" [height]="fixedHeight" [nzSrc]="control.value" alt="" />
      <nz-upload
        [nzBeforeUpload]="beforeUpload"
        [nzShowUploadList]="false"
        [nzMultiple]="false"
        [nzDirectory]="false"
        [nzAccept]="accept">
        <button *ngIf="control.enabled" type="button" nz-button nzGhost nzSize="small" nzShape="circle" title="Upload">
          <mac-icon icon="upload"></mac-icon>
        </button>
      </nz-upload>
    </div>
  </nz-form-control>
</nz-form-item>
