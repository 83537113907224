import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UpdateAccountRequestModel } from './models/update-account.model';
import { AccountResponseModel } from './models/account.model';
import { ChangeAccountPasswordRequestModel } from './models/change-account-password.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private httpClient: HttpClient) {}

  getAccount(): Observable<AccountResponseModel> {
    const url = `${environment.restApiBaseUrl}/api/account`;
    return this.httpClient.get<AccountResponseModel>(url);
  }

  updateAccount(request: UpdateAccountRequestModel): Observable<void> {
    const url = `${environment.restApiBaseUrl}/api/account`;
    return this.httpClient.put<void>(url, request);
  }

  changeAccountPassword(request: ChangeAccountPasswordRequestModel): Observable<void> {
    const url = `${environment.restApiBaseUrl}/api/account/change-password`;
    return this.httpClient.post<void>(url, request);
  }
}
