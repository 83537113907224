<nz-modal [nzVisible]="open" (nzOnCancel)="onCancel()" [nzWidth]="modalWidth" [nzTitle]="titleRef" nzMaskClosable="false">
  <ng-template #titleRef>
    <ng-content select="mac-modal-title"></ng-content>
  </ng-template>

  <div *nzModalContent class="box" #modalContentRef>
    <ng-content select="mac-modal-content"></ng-content>
  </div>

  <div *nzModalFooter>
    <ng-content select="mac-modal-footer"></ng-content>
  </div>
</nz-modal>