import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CustomerCompanyStatus } from '../../../constants/status';

@Component({
  selector: 'customer-company-status',
  templateUrl: './customer-company-status.component.html',
  styleUrls: ['./customer-company-status.component.scss'],
})
export class CustomerCompanyStatusComponent implements OnInit, OnChanges {
  @Input() contract: CustomerCompany;

  cssClass: string;
  label: string;

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.contract);
    if (changes['value']?.currentValue) {
      this.renderStatus();
    }
  }

  private calculateLabel(): string {
    switch (this.contract.status) {
      case 'Pending':
        return 'Shared.InspectionCompanies.Status.Invited';
      case 'Active':
        return 'Shared.InspectionCompanies.Status.Active';
      case 'Terminated':
        return 'Shared.InspectionCompanies.Status.Terminated';
      case 'Upcoming':
        return 'Shared.InspectionCompanies.Status.Upcoming';
      default:
        return '';
    }
  }

  ngOnInit(): void {
    this.renderStatus();
  }

  private renderStatus(): void {
    this.cssClass = `badge ${this.contract.status.toLocaleLowerCase()}`;
    this.label = this.calculateLabel();
  }
}

export interface CustomerCompany {
  contractStartDateUtc: Date;
  contractEndDateUtc?: Date | null | undefined;
  status: CustomerCompanyStatus;
}
