<nz-form-item>
  <nz-form-control>
    <label>{{ label }}</label>
    <div *ngIf="isLoading">
      <nz-spin></nz-spin>
    </div>
    <div class="uploadArea" [style.height]="fixedHeight+'px'" *ngIf="!isLoading" draggable="true">
      <div class="uploadInfo"> {{ 'Portal.InspectionCompanies.Upload.ClickToUpload' | translate }}
      </div>
      <div class="wrapper" [style.height]="fixedHeight+'px'">
        <img *ngIf="imgSrc || uploadedImgSrc" class="imgSrc" [src]="imgSrc ?? uploadedImgSrc"
             [style.maxHeight]="fixedHeight+'px'" />
        <div class="overlay" [style.height]="fixedHeight+'px'">
          <mac-icon class="overlay-icon" style="font-size: 36px;" [icon]="overlayIcon"></mac-icon>
          <input class="upload-text" type="file" (change)="onFileSelected($event)" />
        </div>
      </div>
    </div>
  </nz-form-control>
</nz-form-item>
