<!-- added spinner inside ng template and ng if due to layout rendering and init timing -->
<ng-template #spinner>
  <nz-spin [nzSpinning]="loading" class="spinner"> </nz-spin>
</ng-template>
<ng-container *ngIf="!loading; else spinner">
  <form nz-form [formGroup]="emailFormGroup">
    <ng-container *ngIf="fallbackCultureFormControl">
      <mac-form-input-select
        [label]="'Shared.Communication.FallbackCulture' | translate"
        [placeholder]="'Shared.Communication.FallbackCulturePlaceHolder' | translate"
        [formControl]="fallbackCultureFormControl"
        [options]="availableEmailCultureOptions">
      </mac-form-input-select>
    </ng-container>

    <nz-tabset
      [(nzSelectedIndex)]="selectedTabIndex"
      [nzTabBarExtraContent]="actionsEmailTemplates"
      (nzSelectChange)="onSelectTabChange($event)"
      (nzClose)="removeCultureToAssignment($event)"
      nzHideAdd
      nzLinkRouter>
      <nz-tab *ngFor="let emailTemplate of emailTemplates" [nzClosable]="emailTemplate.canBeDeleted">
        <a *nzTabLink nz-tab-link [routerLink]="['.']" [queryParams]="{ culture: emailTemplate.culture }" queryParamsHandling="merge">
          {{ emailTemplate.culture }}
        </a>
      </nz-tab>
    </nz-tabset>
    <ng-template #actionsEmailTemplates>
      <mac-button
        icon="add"
        nz-dropdown
        size="small"
        [nzDropdownMenu]="emailCultureDropdown"
        nzTrigger="click"
        variant="secondary">
        {{ 'Shared.Communication.AddNewEmailCulture' | translate }}
      </mac-button>
      <nz-dropdown-menu #emailCultureDropdown="nzDropdownMenu">
        <ul nz-menu>
          <li *ngFor="let culture of availableEmailCulturesToAdd" (click)="addCultureToAssignment(culture, true)" nz-menu-item>
            {{ culture }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </ng-template>

    <ng-container *ngIf="currentCultureTab">
      <!-- admin-communication-form set outside of tab content due weird interaction with FroalaEditor, and multiple tabs lazy loading-->
      <app-communication-form
        [height]="250"
        [bodyFormControl]="currentBodyFormControl"
        [subjectFormControl]="currentSubjectFormControl"
        [communicationDefinition]="communicationDefinition">
      </app-communication-form>
    </ng-container>

    <nz-alert
      class="form-error-alert"
      *ngIf="errors && errors.length > 0"
      nzType="error"
      nzShowIcon
      [nzMessage]="errorTitle"
      [nzDescription]="errorDescription">
      <ng-template #errorTitle> {{ 'Shared.Communication.ErrorAlert.InvalidEmailTemplates' | translate }}: </ng-template>
      <ng-template #errorDescription>
        <ng-container *ngFor="let error of errors">
          <app-error-tip [prefixTemplate]="prefix" [errors]="error.errors"></app-error-tip>
          <ng-template #prefix>
            <span> '{{ error.culture }}' {{ error.formControlName | translate }}:</span></ng-template
          >
        </ng-container>
      </ng-template>
    </nz-alert>
    <mac-button
      class="save-button"
      (click)="saveCommunicationAssignment()"
      [disabled]="emailFormGroup.invalid || emailFormGroup.pristine">
      {{ 'Shared.Communication.SaveEmailTemplate' | translate }}
    </mac-button>
  </form>
</ng-container>
