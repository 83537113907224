import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { LanguageService } from '../services/language.service';
import { SecurityService } from '../services/security.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const securityService = this.injector.get(SecurityService);
    const languageService = this.injector.get(LanguageService);
    const accessToken = securityService.getAccessToken();

    let headers = new HttpHeaders({
      'X-Macadam-Language': languageService.getPreferredLanguage(),
    });

    if (accessToken && accessToken !== '') {
      headers = headers.append('Authorization', `Bearer ${accessToken}`);
    }

    const clone = req.clone({ headers: headers });

    return next.handle(clone);
  }
}
