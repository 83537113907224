import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { CustomerContractResponse } from './models/customer-contract.response';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InspectionCompanyCustomerContractService {
  constructor(private httpClient: HttpClient) {
  }

  getCustomerContractsByReference(inspectionCompanyReference: string): Observable<CustomerContractResponse[]> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/references/${inspectionCompanyReference}/customer-contracts`;
    return this.httpClient.get<CustomerContractResponse[]>(url);
  }

  addCustomerContract(inspectionCompanyReference: string, model: AddCustomerContractRequestModel): Observable<CustomerContractResponse> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyReference}/customer-contracts`;
    return this.httpClient.post<CustomerContractResponse>(url, model);
  }

  updateCustomerContract(
    inspectionCompanyReference: string,
    customerContractReference: string,
    model: UpdateCustomerContractRequestModel): Observable<CustomerContractResponse> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyReference}/customer-contracts/${customerContractReference}`;
    return this.httpClient.put<CustomerContractResponse>(url, model);
  }
}

export interface AddCustomerContractRequestModel {
  customerId: number;
  contractStartDateUtc: Date;
  referenceFromCustomer: string;
  forceTanUsage: boolean;
}

export interface UpdateCustomerContractRequestModel {
  forceTanUsage: boolean;
  contractStartDateUtc: Date;
  contractEndDateUtc?: Date | null | undefined;
}
