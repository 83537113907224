import { Component, Input, TemplateRef } from "@angular/core";

@Component({
    selector: 'app-divider',
    templateUrl: './divider.component.html',
  })
  export class DividerComponent {
    @Input() text?: TemplateRef<void> |string | undefined;
    @Input() textAlignment: 'center' | 'left' | 'right' = 'center';
  }
    