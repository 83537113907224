import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found-layout',
  templateUrl: './not-found-layout.component.html',
  styleUrls: ['./not-found-layout.component.scss']
})
export class NotFoundLayoutComponent {
  constructor() {
  }
}
