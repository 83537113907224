<ng-container *ngIf="!translationsLoaded">
    <div nz-row nzJustify="space-around" nzAlign="middle" style="width: 100vw; height: 100vh; overflow: hidden !important;">
        <div>
            <nz-spin nz-col nzSimple></nz-spin>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="translationsLoaded">
    <router-outlet></router-outlet>
</ng-container>