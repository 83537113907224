<mac-modal [(open)]="isVisible" #modal (openChange)="resetAllFormGroupsAndControls()">
  <mac-modal-title>
    {{ 'Shared.ChangePassword.Title' | translate }}
  </mac-modal-title>

  <mac-modal-content>
    <app-alert type="error" [errorKeys]="errors">
    </app-alert>
    <form id="invite-inspection-company-form" nz-form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mac-form-input-password [label]="'Shared.ChangePassword.Password' | translate"
        [placeholder]="'Shared.ChangePassword.Password' | translate" [formControl]="oldPassword">
      </mac-form-input-password>

      <mac-form-input-password [label]="'Shared.ChangePassword.NewPassword' | translate"
        [placeholder]="'Shared.ChangePassword.NewPassword' | translate" [formControl]="newPassword">
      </mac-form-input-password>

      <mac-form-input-password [label]="'Shared.ChangePassword.ConfirmPassword' | translate"
        [placeholder]="'Shared.ChangePassword.ConfirmPassword' | translate" [formControl]="passwordConfirmation">
      </mac-form-input-password>
    </form>
  </mac-modal-content>

  <mac-modal-footer>
    <mac-button form="invite-inspection-company-form" type="submit" [isLoading]="isSubmittingForm"
      [disabled]="form.invalid || form.pristine">
      {{ 'Shared.ChangePassword.Edit' | translate }}
    </mac-button>
  </mac-modal-footer>
</mac-modal>