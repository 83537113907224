import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CommunicationService } from 'projects/shared/services/communications/communication.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NotificationTitle } from 'projects/shared/constants/notifications/notification-message';
import { NotificationContent } from 'projects/shared/constants/notifications/notification-content';
import { CommunicationAssignment } from 'projects/shared/models/communication-assignment';
import { NzTabChangeEvent } from 'ng-zorro-antd/tabs';
import { EmailData } from 'projects/shared/models/email-data';
import { TranslationService } from 'projects/shared/services/translations/translation-service';
import { CultureResponse } from 'projects/shared/services/translations/models/culture.response';
import { CommunicationDefinitionResponse } from 'projects/shared/services/communications/models/communication-definition.response';
import { SharedValidators } from 'projects/shared/validators/shared-validators';
import { DefaultCultureCode } from 'projects/shared/constants/cultures';
import { InspectionCompanyResponse } from 'projects/shared/services/inspection-companies/models/inspection-company.response';
import { SelectOption } from 'projects/component-library/components/input-select/select-option';
import { HttpErrorResponse } from 'projects/shared/services/models/error.response';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-communication-content',
  templateUrl: './communication-content.component.html',
  styleUrls: ['./communication-content.component.scss'],
})
export class CommunicationContentComponent {
  communicationDefinition: CommunicationDefinitionResponse;
  emailFormGroup: FormGroup;
  currentBodyFormControl: FormControl<string | null>;
  currentSubjectFormControl: FormControl<string | null>;
  fallbackCultureFormControl: FormControl<string | null>;
  loading = true;
  selectedTabIndex = 0;
  currentCultureTab: string;
  availableEmailCultures: string[];
  availableEmailCultureOptions: SelectOption[];
  availableEmailCulturesToAdd: string[];
  emailTemplates: EmailTemplate[];
  errors: EmailCultureControlError[] = [];
  private emailCulturesGroup: FormGroup;
  private allCultures: CultureResponse[];
  private communicationId: string;
  private communicationAssignment: CommunicationAssignment;
  private readonly defaultCultureCode = DefaultCultureCode;
  private readonly body = 'body';
  private readonly subject = 'subject';
  private inspectionCompany?: InspectionCompanyResponse;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private communicationService: CommunicationService,
    private nzNotificationService: NzNotificationService,
    private translationService: TranslationService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.communicationId = params['communication'];
      this.inspectionCompany = this.route.snapshot.data['inspectionCompany'];

      const request = {
        communicationDefinition: this.communicationService.getCommunicationDefinition(this.communicationId),
        communicationAssignment: this.communicationService.getCommunicationAssignment(this.communicationId, this.inspectionCompany?.id),
        cultures: this.translationService.cultures$,
      };

      forkJoin(request).subscribe({
        next: ({ communicationDefinition, communicationAssignment, cultures }) => {
          this.communicationDefinition = communicationDefinition;
          this.communicationAssignment = communicationAssignment;
          this.emailTemplates = this.communicationAssignment.emailTemplates.map((x) => new EmailTemplate(x));
          this.allCultures = cultures;

          this.buildForm();
          if (this.emailTemplates.length < 1) {
            this.addCultureToAssignment(this.defaultCultureCode, false);
          } else {
            this.refreshAvailableEmailCulturesAndToAdd(this.allCultures);
            this.setFormControlValues(this.emailTemplates, this.communicationAssignment.defaultCulture);
          }
          this.currentCultureTab = this.emailTemplates[this.selectedTabIndex].culture;
          this.currentBodyFormControl = this.bodyFormControl;
          this.currentSubjectFormControl = this.subjectFormControl;

          // navigate to current culture as query param if route has no culture query param
          if (!this.route.snapshot.queryParams['culture']) {
            this.router.navigate([], { relativeTo: this.route, queryParams: { culture: this.currentCultureTab } });
          }
          this.loading = false;
        },
        error: (response: HttpErrorResponse) => {
          var error = response.error.errors?.length > 0 ? response.error.errors[0] : NotificationContent.DefaultError;
          this.nzNotificationService.error(NotificationTitle.Error, this.translateService.instant(error), { nzDuration: 0 });
        },
      });
    });
  }

  get bodyFormControl(): FormControl {
    return <FormControl>(<FormGroup>this.emailCulturesGroup.controls[this.currentCultureTab]).controls[this.body];
  }

  get subjectFormControl(): FormControl {
    return <FormControl>(<FormGroup>this.emailCulturesGroup.controls[this.currentCultureTab]).controls[this.subject];
  }

  onSelectTabChange(event: NzTabChangeEvent): void {
    this.currentCultureTab = this.emailTemplates[event.index!].culture;
    this.errors = this.getValidationErrors(this.emailCulturesGroup);
    this.currentBodyFormControl = this.bodyFormControl;
    this.currentSubjectFormControl = this.subjectFormControl;
  }

  saveCommunicationAssignment(): void {
    const emailDatas = this.getEmailDatasFromForm(this.emailCulturesGroup);

    this.communicationAssignment.emailTemplates = emailDatas;
    this.communicationAssignment.defaultCulture = this.fallbackCultureFormControl.value!;

    this.communicationService.saveCommunicationAssignment(this.communicationAssignment, this.inspectionCompany?.id).subscribe({
      next: () => {
        this.nzNotificationService.success(
          NotificationTitle.Success,
          this.translateService.instant('Shared.Success.Save'));
        this.emailTemplates = this.getEmailTemplatesCannotBeDeleted(emailDatas);
        this.emailFormGroup.markAsPristine();
        this.setFormControlValues(this.communicationAssignment.emailTemplates, this.communicationAssignment.defaultCulture);
        this.setEmailTemplatesAsNonDeleteable();
      },
      error: () => {
        this.nzNotificationService.error(NotificationTitle.Error, NotificationContent.DefaultError, { nzDuration: 0 });
      },
    });
  }

  getEmailTemplatesCannotBeDeleted(emailDatas: EmailData[]): EmailTemplate[] {
    return emailDatas.map((x) => {
      const emailTemplate = new EmailTemplate(x);
      emailTemplate.canBeDeleted = false;
      return emailTemplate;
    });
  }

  getEmailDatasFromForm(emailCulturesGroup: FormGroup): EmailData[] {
    const emailTemplates: EmailData[] = [];
    for (const key in emailCulturesGroup.controls) {
      const cultureGroup = <FormGroup>emailCulturesGroup.controls[key];

      const emailData: EmailData = {
        culture: key,
        subject: cultureGroup.controls[this.subject].value,
        body: cultureGroup.controls[this.body].value,
      };
      emailTemplates.push(emailData);
    }
    return emailTemplates;
  }

  addCultureToAssignment(culture: string, canBeDeleted: boolean): void {
    const newEmailTemplate: EmailTemplate = {
      subject: '',
      body: '',
      culture: culture,
      canBeDeleted: canBeDeleted,
    };
    this.emailTemplates = this.emailTemplates.concat(newEmailTemplate);
    this.refreshAvailableEmailCulturesAndToAdd(this.allCultures);
    this.selectedTabIndex = this.emailTemplates.indexOf(newEmailTemplate);

    const parameters = this.communicationDefinition.availableParameters.map((x) => x.code);
    const group = this.createEmailTemplateFormGroup(parameters);
    this.emailCulturesGroup.addControl(culture, group);
    this.currentCultureTab = culture;
  }

  removeCultureToAssignment(event: { index: number }): void {
    const toDeleteCulture = this.emailTemplates.find((x, i) => i === event.index)?.culture;
    this.emailTemplates = this.emailTemplates.filter((x, i) => i !== event.index);
    this.emailCulturesGroup.removeControl(toDeleteCulture!);
    this.refreshAvailableEmailCulturesAndToAdd(this.allCultures);
    this.selectedTabIndex = this.emailTemplates.length - 1;
  }

  private getValidationErrors(emailCulturesGroup: FormGroup): EmailCultureControlError[] {
    const errors: EmailCultureControlError[] = [];
    const controls = emailCulturesGroup.controls;

    for (const cultureKey in controls) {
      const cultureGroup = <FormGroup>controls[cultureKey];
      for (const formControlKey in cultureGroup.controls) {
        const formControl = cultureGroup.controls[formControlKey];
        if (formControl.errors) {
          errors.push({ culture: cultureKey, formControlName: formControlKey, errors: formControl.errors });
        }
      }
    }

    return errors;
  }

  private setEmailTemplatesAsNonDeleteable(): void {
    this.emailTemplates.forEach((e) => {
      e.canBeDeleted = false;
    });
  }

  private refreshAvailableEmailCulturesAndToAdd(cultures: CultureResponse[]): void {
    this.availableEmailCultures = this.emailTemplates.map((x) => x.culture);
    this.availableEmailCultureOptions = this.availableEmailCultures.map((x): SelectOption => {
      return { label: x, id: x };
    });
    this.availableEmailCulturesToAdd = cultures.filter((x) => !this.availableEmailCultures.some((y) => y === x.code)).map((x) => x.code);
  }

  private setFormControlValues(emailTemplates: EmailData[], fallbackCulture: string): void {
    const parametersInvalid = emailTemplates?.length < 1;
    if (parametersInvalid) {
      throw new TypeError('Given arguments are invalid or do not contain data.');
    }
    emailTemplates.forEach((email) => {
      const formGroup = <FormGroup>this.emailCulturesGroup.controls[email.culture];
      formGroup.controls[this.subject].setValue(email.subject);
      formGroup.controls[this.subject].updateValueAndValidity();

      formGroup.controls[this.body].setValue(email.body);
      formGroup.controls[this.body].updateValueAndValidity();
    });
    this.fallbackCultureFormControl.setValue(fallbackCulture);
    this.fallbackCultureFormControl.updateValueAndValidity();
  }

  private buildForm(): void {
    const parameters = this.communicationDefinition.availableParameters.map((x) => x.code);
    this.fallbackCultureFormControl = new FormControl<string | null>(null, Validators.required);

    const groupObj: { [key: string]: FormGroup } = {};
    this.emailTemplates.forEach((x) => {
      const formGroup = this.createEmailTemplateFormGroup(parameters);
      groupObj[x.culture] = formGroup;
    });

    this.emailCulturesGroup = this.formBuilder.group({ ...groupObj });

    this.emailFormGroup = this.formBuilder.group({
      fallback: this.fallbackCultureFormControl,
      emailCultures: this.emailCulturesGroup,
    });
  }

  private createEmailTemplateFormGroup(parameters: string[]) {
    const subjectFormControl = new FormControl<string | null>(null, [Validators.required, SharedValidators.unknownParameterValidator(parameters)]);
    const bodyFormControl = new FormControl<string | null>(null, [
      SharedValidators.emptyHtmlValidator,
      SharedValidators.unknownParameterValidator(parameters),
    ]);
    bodyFormControl.valueChanges.subscribe(() => {
      this.errors = this.getValidationErrors(this.emailCulturesGroup);
    });
    subjectFormControl.valueChanges.subscribe(() => {
      this.errors = this.getValidationErrors(this.emailCulturesGroup);
    });

    const formGroup = this.formBuilder.group({
      subject: subjectFormControl,
      body: bodyFormControl,
    });
    return formGroup;
  }
}

class EmailTemplate extends EmailData {
  canBeDeleted: boolean;
}

interface EmailCultureControlError {
  culture: string;
  formControlName: string;
  errors: ValidationErrors | null;
}
