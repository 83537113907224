import { FormControl } from '@angular/forms';

export type validateStatus =
  | 'success'
  | 'warning'
  | 'error'
  | 'validating'
  | FormControl;

export const getValidateStatus = (control: FormControl, isLoading: boolean | null | undefined): validateStatus => {
  if (isLoading) {
    return 'validating';
  }
  if (!control.pristine && control.errors) {
    return 'error';
  }
  return control;
}
