<nz-select
  [nzSuffixIcon]="icon !== undefined ? suffixIcon : null"
  [formControl]="control"
  [nzPlaceHolder]="placeholder ?? null"
  [nzMode]="mode"
  [nzShowSearch]="search"
  [nzAllowClear]="allowClear"
  [nzServerSearch]="search && applyFuzzyFilter"
  (nzOnSearch)="searchTextValue = $event"
  [nzMaxTagCount]="maxTagCount"
  nzSize="large"
  [id]="name"
  title="{{ name }}"
  [nzDropdownStyle]="dropdownStyle"
  [nzTokenSeparators]="tokenSeparators">
  <nz-option
    *ngFor="let option of applyFuzzyFilter ? (options | fuzzyFilter : searchTextValue) : options"
    [nzLabel]="option.label | translate"
    [nzValue]="option.id"></nz-option>
</nz-select>
<ng-template #suffixIcon>
  <mac-icon *ngIf="icon" [icon]="icon"></mac-icon>
  <span id="suffixContent">
    <ng-content></ng-content>
    <!--you can pass <svg> as content to be displayed as icon-->
  </span>
</ng-template>
