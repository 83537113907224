<ng-container *ngIf="!hasErrorLoadingRoles; else errorTemplate">
  <app-roles-permissions-matrix
    [permissions]="portalPermissions"
    [roles]="portalRoles"
    [matrix]="matrix"
    [loading]="isLoadingRoles"
    style="width: 100%;">
  </app-roles-permissions-matrix>
</ng-container>
<ng-template #errorTemplate>
  <app-alert type="error" [title]="'AdministrationPortal.Roles.Info.Error.Title' | translate"
    [message]="'AdministrationPortal.Roles.Info.Error.Message' | translate">
  </app-alert>
</ng-template>
