import { HttpParams } from '@angular/common/http';

export const toHttpParams = (obj: [string, string | number | boolean][] = []): HttpParams => {
  const params = obj.reduce((httpParams, [key, value]) => {
    if (value === null || value === undefined || isEmptyArray(value)) {
      return httpParams;
    } else {
      if (Array.isArray(value)) {
        return value.reduce((params: HttpParams, value: string | number | boolean) => params.append(key, value), httpParams);
      }
      return httpParams.set(key, value);
    }
  }, new HttpParams());
  return params;
};

const isEmptyArray = (value: unknown): boolean => Array.isArray(value) && value.length === 0;
