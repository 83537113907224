<nz-form-item>
  <mac-form-input-text
    class="custom-input"
    [label]="'Shared.Communication.Subject' | translate"
    [placeholder]="'Shared.Communication.Subject' | translate"
    [formControl]="subjectFormControl"
    [addOnAfter]="suffixContent">
  </mac-form-input-text>
  <ng-template #suffixContent>
    <button nz-button nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">%</button>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li
          *ngFor="let availableParameter of communicationDefinition.availableParameters"
          (click)="addParameter(subjectFormControl, availableParameter.code)"
          nz-menu-item>
          {{ availableParameter.code }}
        </li>
      </ul>
    </nz-dropdown-menu>
  </ng-template>
</nz-form-item>
<textarea [id]="'froala'" (froalaInit)="froalaInit($event)" [froalaEditor]="froalaOptions"></textarea>
