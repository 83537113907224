import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

import { PermissionLevel } from '../constants/permission-level';
import { SecurityService } from '../../shared/services/security.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective implements OnChanges {
  permissions: string[] = [];
  @Input() appHasPermissionLevel: '' | 'root' = '';

  constructor(private securityService: SecurityService, private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @Input()
  set appHasPermission(val: string[]) {
    this.permissions = val;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.updateView();
    }
  }

  private updateView(): void {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission(): boolean {
    switch (this.appHasPermissionLevel) {
      case 'root':
        return this.permissions.some(p => this.securityService.hasPermissionOnLevel(p, PermissionLevel.Root));
      default:
        return this.permissions.some(p => this.securityService.hasPermissionOnLevel(p));
    }
  }
}
