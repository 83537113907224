<!-- added spinner inside ng template and ng if due to layout rendering and init timing -->
<ng-template #spinner>
  <nz-spin [nzSpinning]="loading" class="spinner"> </nz-spin>
</ng-template>
<ng-container *ngIf="!loading; else spinner">
  <nz-layout class="inner-layout">
    <nz-sider nzWidth="200px" nzTheme="light">
      <ul nz-menu nzMode="inline" class="sider-menu">
        <li nz-menu-group [nzTitle]="'AdministrationPortal.Navigation.Communications' | translate">
          <ul>
            <li
              class="communication-menu-item"
              nz-menu-item
              *ngFor="let communication of communications"
              nzMatchRouter
              [routerLink]="communication.id">
              {{ communication.type }}
            </li>
          </ul>
        </li>
      </ul>
    </nz-sider>
    <nz-content class="inner-content">
      <router-outlet></router-outlet>
    </nz-content>
  </nz-layout>
</ng-container>
