import { Component } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { createErrorKey } from 'projects/shared/utils/getErrorMessage';
import { FormItemComponentBase } from '../form-item-base.component';

@Component({
  selector: 'app-form-item-date',
  templateUrl: './form-item-date.component.html',
  styleUrls: ['./form-item-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormItemDateComponent,
    },
  ],
})
export class FormItemDateComponent extends FormItemComponentBase implements ControlValueAccessor {
  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }

  createErrorKey(key: string): string {
    return createErrorKey(key);
  }
}
