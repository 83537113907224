import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { PermissionLevel } from '../constants/permission-level';
import { SecurityService } from '../services/security.service';

@Injectable()
export class PermissionGuard  {
  constructor(private securityService: SecurityService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean {
    const data = route.data as PermissionGuardData;
    const hasPermission = data.expectedPermissions.some(p => this.securityService.hasPermissionOnLevel(p, data.permissionLevel));
    if (!hasPermission) {
      this.router.navigate(['unauthorized']);
    }
    return hasPermission;
  }
}

export interface PermissionGuardData {
  expectedPermissions: string[];
  permissionLevel: PermissionLevel;
}
