<nz-form-item>
  <nz-form-label
    *ngIf="label"
    [nzSpan]="labelSpan || null"
    [nzFor]="name || formControlName"
    [nzRequired]="required && control.enabled ? true : undefined">
    {{ label }}
  </nz-form-label>
  <nz-form-control [nzSpan]="inputSpan" [nzErrorTip]="error | translate" [nzExtra]="message" [nzValidateStatus]="validateStatus" nzHasFeedback>
    <nz-input-group [nzSuffix]="suffix" *ngIf="(showAsTextIfDisabled && control.enabled) || !showAsTextIfDisabled">
      <input
        nz-input
        [formControl]="control"
        [placeholder]="placeholder"
        [type]="type"
        [name]="name"
        [id]="name"
        title="{{ name }}" />
    </nz-input-group>
    <nz-form-label *ngIf="showAsTextIfDisabled && control.disabled" nzNoColon>
      {{ control.value }}
    </nz-form-label>
  </nz-form-control>
</nz-form-item>
