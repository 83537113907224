import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'projects/shared/services/language.service';
import { TranslationService } from 'projects/shared/services/translations/translation-service';

@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
})
export class LanguageButtonComponent implements OnInit {
  isLoadingLanguages: boolean = false;
  errorLoadingLanguages: boolean = false;
  availableLanguages: Language[] = [];
  currentLanguage: string;

  constructor(private translationService: TranslationService, private languageService: LanguageService) {}

  ngOnInit(): void {
    this.fetchLanguages();
    this.checkInitialLanguage();
  }

  onChangeLanguage(language: Language): void {
    this.languageService.setPreferredLanguage(language.culture);
    this.currentLanguage = language.culture;
  }

  isCurrentLanguage(language: Language): boolean {
    return language.culture.toLowerCase() === this.currentLanguage.toLowerCase();
  }

  private fetchLanguages(): void {
    this.isLoadingLanguages = true;
    this.translationService.getProjectLanguages().subscribe({
      next: (languages) => {
        this.availableLanguages = languages.sort((a, b) => a.name.localeCompare(b.name));
      },
      error: () => {
        this.errorLoadingLanguages = true;
        this.isLoadingLanguages = false;
      },
      complete: () => {
        this.isLoadingLanguages = false;
      },
    });
  }

  private checkInitialLanguage() {
    const initialLanguage = this.languageService.getPreferredLanguage();
    if (initialLanguage) {
      this.onChangeLanguage({ name: '', culture: initialLanguage });
    }
  }

  trackByLanguage(_index: number, item: Language): string {
    return item.culture;
  }
}

interface Language {
  name: string;
  culture: string;
}
