import { Component, OnInit } from '@angular/core';
import { RoleAndPermissionsResponse } from 'projects/shared/services/roles/models/role-and-permissions.response';
import { RoleService } from 'projects/shared/services/roles/role.service';

@Component({
  selector: 'app-roles-info-page',
  templateUrl: './roles-info.page.html',
  styleUrls: ['./roles-info.page.scss'],
})
export class RolesInfoPageComponent implements OnInit {
  public readonly portalRoles = [
    '9dc557fa-8bf6-410b-85a5-94ed9b6e1033', // Inspector
    '5018a7cd-ad02-4fa3-8abe-d2c0c1dcad5c', // Editor
    'ca77edec-66c1-49f1-8754-7f1df8418355', // Back Office user
    '8603c648-1d68-4171-86a9-440736a04717', // Inspection portal user
    'b84530da-4de4-4911-8245-501332928267', // Owner
  ];

  public readonly portalPermissions = [
    '9dc557fa-8bf6-410b-85a5-94ed9b6e1033', // Login carcheckapp
    '39f804dc-7495-4f0a-9cf6-37fe30948c18', // Read own inspections
    '60d88b71-7a8c-497c-8918-a71e92b024a0', // Edit own inspections
    'c8aa3719-13c8-459b-99f3-ee579f1d1610', // Edit other inspections
    '98e573d3-d499-4bcf-80ba-31c2768bc002', // Login inspection portal
    '6356ffd5-26db-4fea-a84c-d91dc73e15c0', // Login IC portal
  ];

  matrix: Record<string, Record<string, boolean>> = {};
  hasErrorLoadingRoles = false;
  isLoadingRoles = false;

  constructor(private readonly roleService: RoleService) {}

  ngOnInit(): void {
    this.fetchRolesAndPermissions();
  }

  private fetchRolesAndPermissions(): void {
    this.hasErrorLoadingRoles = false;
    this.isLoadingRoles = true;

    this.roleService.getRoles(this.portalRoles).subscribe({
      next: (roles) => {
        this.matrix = this.createMatrix(roles);
      },
      error: () => {
        this.hasErrorLoadingRoles = true;
        this.isLoadingRoles = false;
      },
      complete: () => {
        this.isLoadingRoles = false;
      },
    });
  }

  private createMatrix(roles: RoleAndPermissionsResponse[]): Record<string, Record<string, boolean>> {
    return roles.reduce<Record<string, Record<string, boolean>>>((acc, role) => {
      acc[role.reference] = this.portalPermissions.reduce<Record<string, boolean>>((acc, permission) => {
        acc[permission] = role.permissions.map((x) => x.reference).includes(permission);
        return acc;
      }, {});
      return acc;
    }, {});
  }
}
