import { Component, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzSelectModeType } from 'ng-zorro-antd/select/select.types';
import { createErrorKey } from 'projects/shared/utils/getErrorMessage';
import { SelectOption } from '../../input-select/select-option';
import { FormInputComponentBase } from '../form-input-base.component';

@Component({
  selector: 'mac-form-input-select',
  templateUrl: './form-input-select.component.html',
  styleUrls: ['../form-input-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormInputSelectComponent,
    },
  ],
})
export class FormInputSelectComponent extends FormInputComponentBase implements ControlValueAccessor {
  @Input() mode: NzSelectModeType = 'default';
  @Input() icon?: string;
  @Input() allowClear = true;
  @Input() search = false;
  @Input() applyFuzzyFilter = false;
  @Input() maxTagCount: number = Infinity;
  @Input() options: SelectOption[];

  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }

  createErrorKey(key: string): string {
    return createErrorKey(key);
  }
}
