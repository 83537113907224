import { Component, OnInit } from '@angular/core';
import { SecurityService } from 'projects/shared/services/security.service';

@Component({
  selector: 'app-authorize',
  templateUrl: './authorize.component.html',
})
export class AuthorizeComponent implements OnInit {

  constructor(private securityService: SecurityService) {
  }

  ngOnInit() {
    if (!window.location.hash.startsWith('#id_token')) {
      window.location.href = '/';
    }

    this.securityService.authorizeCallback();
  }
}
