<div class="ant-form-item-explain-error" *ngIf="errors">
    <ng-container *ngIf="prefixTemplate" [ngTemplateOutlet]="prefixTemplate"></ng-container>
    <ng-container *ngIf="errors['unknownParameter']">
        {{errorMessage('unknownParameter') | translate}} 
        {{ errors['unknownParameter'].value }}
    </ng-container>
    
    <ng-container *ngIf="errors['emptyHtml']">
        {{errorMessage('emptyHtml') | translate}}
    </ng-container>
    
    <ng-container *ngIf="errors['required']">
        {{errorMessage('required') | translate}}
    </ng-container>
</div>