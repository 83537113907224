import { throwError as observableThrowError,  Observable } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((errorResponse: HttpErrorResponse) => {
      if (errorResponse && errorResponse.url != null && !errorResponse.url.includes('api/admin/security/isadmin')) {
        if (errorResponse.status === 401) {
          this.router.navigate(['unauthorized']);
        }
      }

      return observableThrowError(errorResponse);
    }));
  }
}
