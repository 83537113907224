import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-roles-permissions-matrix',
  templateUrl: './roles-permissions-matrix.component.html',
  styleUrls: ['./roles-permissions-matrix.component.scss'],
})
export class RolesPermissionsMatrixComponent {
  @Input() matrix: Record<string, Record<string, boolean>> = {};
  @Input() permissions: string[] = [];
  @Input() roles: string[] = [];
  @Input() loading = false;

  trackByString(_index: number, item: string): string {
    return item;
  }
}
