import { SelectOption } from '../components/input-select/select-option';

export function fuzzyFilterSelectOptions(allOptions: SelectOption[], searchValue: string): SelectOption[] {
  return allOptions.filter((x) => stringCompareDiacriticsInsensitive(x.label, searchValue));
}

export function stringCompareDiacriticsInsensitive(value: string, searchValue: string): boolean {
  const norm = 'NFD';
  const diacritics = /\p{Diacritic}/gu;
  return value.toUpperCase().normalize(norm).replace(diacritics, '').includes(searchValue.toUpperCase().normalize(norm).replace(diacritics, ''));
}
