import { EmailData } from "./email-data";

export class CommunicationAssignment {
  inspectionCompanyId?: number;
  noCommunication: boolean;
  communicationId: string;
  defaultCulture: string;
  emailTemplates: EmailData[];

  constructor(communicationId: string, inspectionCompanyId?: number) {
    this.communicationId = communicationId;
    this.inspectionCompanyId = inspectionCompanyId;
    this.emailTemplates = [];
    this.noCommunication = false;
  }
}
