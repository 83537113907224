<nz-form-item>
  <nz-form-label
    *ngIf="label"
    [nzSpan]="labelSpan || null"
    [nzFor]="controlName"
    [nzRequired]="required && control.enabled ? true : undefined">
    {{ label }}
  </nz-form-label>
  <nz-form-control [nzSpan]="selectSpan" [nzValidateStatus]="validateStatus" [nzErrorTip]="error | translate"
                   [nzExtra]="message" nzHasFeedback>
    <nz-select
      [nzMode]="mode"
      *ngIf="(showAsTextIfDisabled && control.enabled) || !showAsTextIfDisabled"
      [nzPlaceHolder]="placeholder || ''"
      nzShowSearch
      [formControl]="control">
      <nz-option *ngFor="let option of options" [nzValue]="option.id" [nzLabel]="option.label | translate"></nz-option>
    </nz-select>
    <nz-form-label *ngIf="showAsTextIfDisabled && control.disabled" nzNoColon>
      {{ control.value }}
    </nz-form-label>
  </nz-form-control>
</nz-form-item>
