import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AntdModule } from './antd.module';
import { ButtonComponent } from './components/button/button.component';
import { FormInputSelectComponent } from './components/form-items/form-input-select/form-input-select.component';
import { FormInputTextComponent } from './components/form-items/form-input-text/form-input-text.component';
import { IconComponent } from './components/icon/icon.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalContentComponent } from './components/modal/modal.content.component';
import { ModalFooterComponent } from './components/modal/modal.footer.component';
import { ModalTitleComponent } from './components/modal/modal.title.component';
import { FuzzyFilterPipe } from './pipes/fuzzy-filter.pipe';
import { FormInputFileComponent } from './components/form-items/form-input-file/form-input-file.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { FormInputPasswordComponent } from './components/form-items/form-input-password/form-input-password.component';
import { ModalCancelButtonComponent } from './components/modal/modal.cancel-button.component';
import { ModalDeleteButtonComponent } from './components/modal/modal.delete-button.component';
import { RouterModule } from '@angular/router';

const components = [
  ButtonComponent,
  FormInputSelectComponent,
  FormInputTextComponent,
  FormInputPasswordComponent,
  IconComponent,
  InputSelectComponent,
  InputTextComponent,
  FormInputFileComponent,
  ModalCancelButtonComponent,
  ModalDeleteButtonComponent,
  ModalComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalTitleComponent,
  PageTitleComponent
];
const pipes = [FuzzyFilterPipe];

@NgModule({
  declarations: [...components, ...pipes],
  exports: [AntdModule, FormsModule, ReactiveFormsModule, ...components, ...pipes],
  imports: [AntdModule, FormsModule, ReactiveFormsModule, TranslateModule, CommonModule, RouterModule],
})
export class ComponentLibraryModule {}
