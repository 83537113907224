import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from 'projects/component-library/components/input-select/select-option';
import {
  getValidateStatus,
  validateStatus,
} from '../../../../component-library/components/form-items/validation/validateStatus';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
  ],
})
export class SelectComponent implements ControlValueAccessor {
  @Input() name?: string;
  @Input() label?: string | undefined;
  @Input() required? = false;
  @Input() showAsTextIfDisabled = false;
  @Input() loading?: boolean | undefined | null = false;
  @Input() message?: string | undefined;
  @Input() placeholder? = '';
  @Input() options?: SelectOption[] = [];
  @Input() labelSpan?: number | undefined | null;
  @Input() mode: 'multiple' | 'tags' | 'default' = 'default';

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Input() selectSpan: number;

  constructor(private controlContainer: ControlContainer) {}

  get control(): FormControl<any> {
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  get controlName(): string {
    return this.name || this.formControlName;
  }

  get error(): string {
    if (!this.control.errors) {
      return '';
    }
    return Object.keys(this.control.errors!)[0];
  }

  get validateStatus(): validateStatus {
    return getValidateStatus(this.control, this.loading);
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(value);
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState!(isDisabled);
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
  }
}
