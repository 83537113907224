import { Component, Input, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  getValidateStatus,
  validateStatus,
} from '../../../../component-library/components/form-items/validation/validateStatus';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputComponent,
    },
  ],
})
export class InputComponent implements ControlValueAccessor {
  @Input() name?: string;
  @Input() placeholder? = '';
  @Input() type: 'text' | 'email' | 'password' = 'text';
  @Input() label?: string;
  @Input() labelSpan?: number | null;
  @Input() required? = false;
  @Input() showAsTextIfDisabled = false;
  @Input() loading?: boolean | null = false;
  @Input() message?: string;
  @Input() suffix?: string = '';
  @Input() inputSpan: number;

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;
  @Input() formControl: FormControl;
  @Input() formControlName: string;

  touched = false;
  disabled = false;
  value: string;

  constructor(private controlContainer: ControlContainer) {}

  get control(): FormControl<any> {
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  get isInputGroup(): boolean {
    return !!this.suffix;
  }

  onChange!: (_: any) => void;
  onTouched!: () => void;

  get error(): string {
    if (!this.control.errors) {
      return '';
    }
    return Object.keys(this.control.errors!)[0];
  }

  get validateStatus(): validateStatus {
    return getValidateStatus(this.control, this.loading);
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(value);
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState!(isDisabled);
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
    this.onTouched = fn;
  }
}
