import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, shareReplay, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { CultureResponse } from './models/culture.response';
import { LanguageResponse } from './models/language.response';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private httpClient: HttpClient) {}

  cultures$ = this.getCultures()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  public getCultures(): Observable<CultureResponse[]> {
    const url = `${environment.restApiBaseUrl}/api/cultures`;
    return this.httpClient.get<CultureResponse[]>(url);
  }

  getProjectLanguages(): Observable<LanguageResponse[]> {
    const url = `${environment.restApiBaseUrl}/i18n/languages`;
    return this.httpClient.get<LanguageResponse[]>(url);
  }
}
