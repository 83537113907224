import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, tap } from 'rxjs';

import { environment } from '../../environments/environment';
import { RoleAndPermissionsResponse } from './models/role-and-permissions.response';
import { RoleResponse } from './models/role.response';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private httpClient: HttpClient) {}

  assignableRoles$ = this.getPortalRoles()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  /**
   * Get the list of roles that can be assigned to a user.
   */
  getPortalRoles(): Observable<RoleResponse[]> {
    const url = `${environment.restApiBaseUrl}/api/roles/assignable`;
    return this.httpClient.get<RoleResponse[]>(url);
  }

  /**
   * Get the detailed information about a role.
   * @param roleIds list of role ids to get detailed information about.
   */
  getRoles(roleIds: string[]): Observable<RoleAndPermissionsResponse[]> {
    const url = `${environment.restApiBaseUrl}/api/roles`;
    const opts = {
      params: new HttpParams({ fromObject: { roleId: roleIds } }),
    };
    return this.httpClient.get<RoleAndPermissionsResponse[]>(url, opts);
  }
}
