import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as Oidc from 'oidc-client';
import { isNullOrUndefined } from 'is-what';

import { environment } from '../environments/environment';
import { PermissionLevel } from '../constants/permission-level';
import { UserRole } from '../models/user-role';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private static readonly InternalEmailPattern = /^.+@(macadam\.(eu|be|de)|mv-inspection.be)$/i;

  private _tokenManager: Oidc.UserManager;
  private _userProfile: Oidc.User;
  private _userRoles: UserRole[] = [];

  constructor(private router: Router, private httpClient: HttpClient) {}

  async login(properRedirectUrl = '') {
    const tokenManager = await this.getTokenManager();
    tokenManager.signinRedirect({ state: properRedirectUrl }).catch(this.logError);
  }

  async authorizeCallback() {
    const tokenManager = await this.getTokenManager();
    const user = await tokenManager.signinRedirectCallback();
    if (!isNullOrUndefined(user.state)) {
      this.router.navigateByUrl(user.state).catch(this.logError);
    } else {
      this.router.navigate(['']).catch(this.logError);
    }
  }

  async logout() {
    const tokenManager = await this.getTokenManager();
    const user = await tokenManager.getUser();
    tokenManager.signoutRedirect({ id_token_hint: user!.id_token }).catch(this.logError);
  }

  getUserFullName(): string {
    if (isNullOrUndefined(this._userProfile)) {
      return '';
    }
    return this._userProfile.profile.name ?? '';
  }

  getUser(): LoggedInUser | null {
    if (!this._userProfile) {
      return null;
    }
    return {
      firstName: this._userProfile.profile.given_name || '',
      userId: Number(this._userProfile.profile.sub),
      lastName: this._userProfile.profile.family_name || '',
      name: this.getUserFullName(),
      email: this._userProfile.profile.email || '',
      locale: this._userProfile.profile.locale || ''
    };
  }

  async isLoggedIn(): Promise<boolean> {
    try {
      const tokenManager = await this.getTokenManager();
      const user = await tokenManager.getUser();
      this._userProfile = user!;
      if (user === null) {
        return false;
      }

      if (user.expires_in < 0) {
        await tokenManager.removeUser();
        return false;
      }

      await this.getUserRoles();
      return true;
    } catch (error) {
      this.logError(error);
      return false;
    }
  }

  getAccessToken(): string {
    if (this._userProfile === undefined || this._userProfile === null) {
      return '';
    }
    return this._userProfile.access_token;
  }

  public hasPermission(expectedPermission: string): boolean {
    return this._userRoles.some((x) => x.permissions.some((p) => p.key === expectedPermission.toLowerCase()));
  }

  public hasPermissionOnLevel(expectedPermission: string, permissionLevel: PermissionLevel = PermissionLevel.Any): boolean {
    return this._userRoles.some(
      (x) => x.permissions.some((p) => p.key === expectedPermission.toLowerCase()) && permissionLevel === PermissionLevel.Any
    );
  }

  public hasPermissionOrChildPermission(expectedPermission: String): boolean {
    return this._userRoles.some((x) => x.permissions.some((p) => p.key === expectedPermission.toLowerCase()));
  }

  public isInternalUser(): boolean {
    if (!this._userProfile) {
      return false;
    }
    return SecurityService.InternalEmailPattern.test(this._userProfile.profile.email ?? '');
  }

  private async getUserRoles(): Promise<void> {
    const accessToken = this.getAccessToken();
    const url = `${environment.restApiBaseUrl}/api/security/roles`;

    try {
      const userRoles = await this.httpClient
        .get<UserRole[]>(url, {
          headers: new HttpHeaders().set('Authorization', `Bearer ${accessToken}`)
        })
        .toPromise();
      if (!isNullOrUndefined(userRoles)) {
        this._userRoles = userRoles;
      }
    } catch (error) {
      console.error(error);
    }
  }

  private async getTokenManager(): Promise<Oidc.UserManager> {
    if (!isNullOrUndefined(this._tokenManager)) {
      return this._tokenManager;
    }
    const settings = {
      authority: environment.idServer,
      client_id: 'inspectioncompanies',

      redirect_uri: `${window.location.origin}/authorize`,
      post_logout_redirect_uri: window.location.origin,

      response_type: 'id_token token',
      scope: 'openid profile email macadaminspectioncompaniesapi',

      silent_redirect_uri: `${window.location.origin}/auth/index.html`,
      automaticSilentRenew: true,

      filterProtocolClaims: true,
      loadUserInfo: true,
      userStore: new Oidc.WebStorageStateStore({ store: localStorage })
    };

    this._tokenManager = new Oidc.UserManager(settings);

    this._tokenManager.events.addUserLoaded((user) => {
      this._userProfile = user;
    });
    return this._tokenManager;
  }

  private logError(error: any) {
    console.log(error);
    window.location.href = '/';
  }
}

export interface LoggedInUser {
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  locale: string;
  userId: number;
}
