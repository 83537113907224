import { Component, Input } from '@angular/core';

@Component({
  selector: 'customer-logo',
  templateUrl: './customer-logo.component.html',
  styleUrls: ['./customer-logo.component.scss']
})
export class CustomerLogoComponent {
  @Input() logo?: string | null;
}
