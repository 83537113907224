<form nz-form [formGroup]="form">
  <div>
    <mac-form-input-text style="width: 100%;" [label]="'Shared.UserForm.Email' | translate"
      [placeholder]="'Shared.UserForm.EmailPlaceHolder' | translate" [formControl]="emailControl">
    </mac-form-input-text>
    <mac-form-input-text [label]="'Shared.UserForm.FirstName' | translate"
      [placeholder]="'Shared.UserForm.FirstNamePlaceHolder' | translate" [formControl]="firstNameControl">
    </mac-form-input-text>
    <mac-form-input-text [label]="'Shared.UserForm.LastName' | translate"
      [placeholder]="'Shared.UserForm.LastNamePlaceHolder' | translate" [formControl]="lastNameControl">
    </mac-form-input-text>
    <mac-form-input-select [label]="'Shared.UserForm.Culture' | translate" [options]="cultures"
      [placeholder]="'Shared.UserForm.CulturePlaceHolder' | translate" [formControl]="cultureControl">
    </mac-form-input-select>
    <mac-form-input-text style="width: 100%;" [label]="'Shared.UserForm.Password' | translate"
      [actionLabel]="'Shared.UserForm.Password.ChangePasswordAction' | translate" (actionLabelClick)="onChangePassword()" [formControl]="passwordControl">
    </mac-form-input-text>
  </div>
</form>

<app-change-password-modal #ChangePasswordModal (actionComplete)="onActionComplete()"
  [isVisible]="changePasswordModalVisible"></app-change-password-modal>
