import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mac-modal-delete-button',
  template: `
    <mac-button variant="danger" (click)="onCancel()" icon="delete" [disabled]="isDisabled">
      {{ 'Shared.Modal.DeleteButton.Label' | translate }}
    </mac-button>
  `,
})
export class ModalDeleteButtonComponent {
  @Input() isDisabled = false;
  @Output() cancel = new EventEmitter<void>();

  public onCancel() {
    this.cancel.emit();
  }
}
