import { Component, Input } from '@angular/core';

@Component({
  selector: 'force-tan',
  templateUrl: './force-tan.component.html',
  styleUrls: ['./force-tan.component.scss']
})
export class ForceTanComponent {
  forceTanLabel: string;

  @Input() set value(forceTan: boolean) {
    this.forceTanLabel = forceTan
      ? 'Shared.InspectionCompanies.ForceTanUsage.On'
      : 'Shared.InspectionCompanies.ForceTanUsage.Off';
  }
}
