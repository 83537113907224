<mac-button icon="language" variant="text" size="small" padding="compact" [isLoading]="isLoadingLanguages" nz-dropdown
  [nzDropdownMenu]="errorLoadingLanguages ? errorLoadingLanguagesTemplate : languagesSelectTemplate" nzTrigger="click">
</mac-button>

<nz-dropdown-menu #languagesSelectTemplate="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li nz-menu-item *ngFor="let language of availableLanguages; trackBy: trackByLanguage"
      (click)="onChangeLanguage(language)" [nzSelected]="isCurrentLanguage(language)">
      <mac-icon [style.visibility]="isCurrentLanguage(language) ? 'visible' : 'hidden'" icon="check"
        style="margin-right: 0.25rem;"></mac-icon>
      {{ language.name }}
    </li>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #errorLoadingLanguagesTemplate="nzDropdownMenu">
  <ul nz-menu [nzSelectable]="false">
    <li nz-menu-item [nzDisabled]="true" nzDanger>
      {{ 'Shared.SelectLanguage.Error.LoadingLanguages' | translate }}
    </li>
  </ul>
</nz-dropdown-menu>