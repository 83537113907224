import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-nav-bar',
  templateUrl: './setting-nav-bar.component.html',
  styleUrls: ['./setting-nav-bar.component.scss'],
})
export class SettingNavBarComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
    this.navItems = [
      { link: '../profile', label: 'Shared.ProfileSettings' },
      //{ link: '../web-services', label: 'Shared.WebServices' },
    ];
  }
  @Input() logoSrc: string | null;
  navItems: NavItem[] = [];
}

export interface NavItem {
  label: string;
  link: string;
}
