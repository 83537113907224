import { NgModule } from '@angular/core';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule, NzIconService } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzModalModule, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzPopoverModule } from 'ng-zorro-antd/popover';

import {
  GlobalOutline,
  HomeOutline,
  LogoutOutline,
  MailOutline,
  MinusCircleOutline,
  PlusOutline,
  ProjectOutline,
  DownOutline,
  UpOutline,
  UserAddOutline,
  FilterFill,
} from '@ant-design/icons-angular/icons';

const icons: IconDefinition[] = [
  HomeOutline,
  ProjectOutline,
  PlusOutline,
  LogoutOutline,
  GlobalOutline,
  MinusCircleOutline,
  MailOutline,
  DownOutline,
  UpOutline,
  UserAddOutline,
  FilterFill,
];

const modules = [
  NzTableModule,
  NzPaginationModule,
  NzTagModule,
  NzButtonModule,
  NzLayoutModule,
  NzImageModule,
  NzListModule,
  NzGridModule,
  NzCardModule,
  NzPageHeaderModule,
  NzSpaceModule,
  NzDrawerModule,
  NzFormModule,
  NzToolTipModule,
  NzSelectModule,
  NzDropDownModule,
  NzInputModule,
  NzDatePickerModule,
  NzCheckboxModule,
  NzNotificationModule,
  NzModalModule,
  NzSpinModule,
  NzDividerModule,
  NzAlertModule,
  NzResultModule,
  NzTabsModule,
  NzDescriptionsModule,
  NzUploadModule,
  NzCollapseModule,
  NzTypographyModule,
  NzAvatarModule,
  NzSkeletonModule,
  NzEmptyModule,
  NzPopoverModule,
];

@NgModule({
  imports: [...modules, NzIconModule.forRoot(icons)],
  exports: [...modules, NzIconModule],
  providers: [
    NzModalService,
    NzIconService
  ],
})
export class AntdModule {}
