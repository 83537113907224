import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ControlContainer, FormControlDirective } from '@angular/forms';
import { getValidateStatus, validateStatus } from 'projects/component-library/components/form-items/validation/validateStatus';

@Component({ template: '' })
export abstract class FormInputComponentBase {
  @Input() name?: string;
  @Input() label: string;
  @Input() actionLabel?: string | null = null;
  @Output() actionLabelClick: EventEmitter<void> = new EventEmitter();
  @Input() placeholder? = '';
  @Input() type: 'text' | 'email' | 'password' = 'text';
  @Input() loading?: boolean | null = false;
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Input() inputSpan: number;
  @Input() message?: string;

  constructor(protected controlContainer: ControlContainer) {}

  get control(): FormControl<any> {
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  get error(): string {
    if (!this.control.errors) {
      return '';
    }
    const errorKey = Object.keys(this.control.errors!)[0];
    return errorKey;
  }

  get validateStatus(): validateStatus {
    return getValidateStatus(this.control, this.loading);
  }

  onActionLabelClick(): void {
    this.actionLabelClick.emit();
  }

  formControlDirective: FormControlDirective;
  touched = false;

  onChange!: (_: any) => void;
  onTouched!: () => void;

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(value);
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState!(isDisabled);
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
    this.onTouched = fn;
  }
}
