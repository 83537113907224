import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class SharedValidators {
  static unknownParameterValidator(parameters: string[]): ValidatorFn {
    const func = (control: AbstractControl): ValidationErrors | null => {
      const html = control.value as string;
      const regex = /%%([a-zA-Z]*)%%/g;
      const usedParameters = [];

      let match: RegExpExecArray | null;
      while ((match = regex.exec(html))) {
        usedParameters.push(match[1]);
      }

      const unknownParameters = usedParameters.filter((up) => parameters.indexOf(up) === -1);
      if (unknownParameters.length) {
        return { unknownParameter: { value: unknownParameters } };
      }
      return null;
    };
    return func;
  }

  static emptyHtmlValidator(control: AbstractControl): ValidationErrors | null {
    let html = control.value as string;
    if (!html) {
      return { emptyHtml: true };
    }
    const whiteSpaceRegex = /\s/g;
    const tagRemovalRegex = /<(?!img|\/img).*?>/g;
    html = html
      .replace(whiteSpaceRegex, '')
      .replace(tagRemovalRegex, '')
      .replace(/&nbsp;/g, ' ')
      .trim();
    if (html.length === 0) {
      return { emptyHtml: true };
    }

    return null;
  }

  static vatNumberValidator(control: AbstractControl): ValidationErrors | null {
    const vatNumber = control.value as string;
    const regex = /^((AT)?U[0-9]{8}|(BE)?0[0-9]{9}|(BG)?[0-9]{9,10}|(CY)?[0-9]{8}L|(CZ)?[0-9]{8,10}|(DE)?[0-9]{9}|(DK)?[0-9]{8}|(EE)?[0-9]{9}|(EL|GR)?[0-9]{9}|(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)?[0-9]{8}|(FR)?[0-9A-Z]{2}[0-9]{9}|(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)?[0-9]{8}|(IE)?[0-9]S[0-9]{5}L|(IT)?[0-9]{11}|(LT)?([0-9]{9}|[0-9]{12})|(LU)?[0-9]{8}|(LV)?[0-9]{11}|(MT)?[0-9]{8}|(NL)?[0-9]{9}B[0-9]{2}|(PL)?[0-9]{10}|(PT)?[0-9]{9}|(RO)?[0-9]{2,10}|(SE)?[0-9]{12}|(SI)?[0-9]{8}|(SK)?[0-9]{10})$/;
    if (!regex.test(vatNumber)) {
      return { VatNumber: true };
    }
    return null;
  }

  static subdomainValidator(control: AbstractControl): ValidationErrors | null {
    const subdomain = control.value as string;
    const regex = /^[a-zA-Z0-9][a-zA-Z0-9\-]*$/;
    if (!regex.test(subdomain)) {
      return { Subdomain: true };
    }
    return null;
  }

  /**
   * Validate the value of a control against the value of another control.
   */
  static matchValidator(matchTo: AbstractControl, errorKey?: string | null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const key = errorKey || 'notmatching';
      return control.value === matchTo.value ? null : { [key]: true };
    };
  }

  /**
   * Validate a FormArray to have a minimum length.
   * @param min Minimum length of the FormArray.
   */
  // https://stackoverflow.com/questions/42184800/how-to-validate-formarray-length-in-angular2#answer-44574867
  static minLengthArray(min: number, key: string | null = null): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control?.value?.length >= min) {
        return null;
      }

      return { [key || 'minLengthArray']: true };
    };
  }
}

/**
 * Trigger all validators on a form.
 * @param form FormGroup to validate.
 */
export const validateForm = (form: FormGroup): void => {
  Object.keys(form.controls).forEach((key) => {
    const control = form.controls[key];

    if (control instanceof FormGroup) {
      // We're dealing with nested form groups.
      validateForm(control);
    } else if (control instanceof FormArray) {
      validateFormArray(control);
    } else {
      validateControl(control);
    }
  });
};

const validateFormArray = (form: FormArray): void => {
  form.markAllAsTouched();
  for (const field in form.controls) {
    const control = form.controls[field];
    if (control instanceof FormGroup) {
      // We're dealing with nested form groups.
      validateForm(control);
    } else if (control instanceof FormArray) {
      validateFormArray(control);
    } else {
      validateControl(control);
    }
  }
};

const validateControl = (control: AbstractControl): void => {
  control.markAsTouched();
  control.updateValueAndValidity();
};
