<form [formGroup]="icForm" (ngSubmit)="saveCompanyDetails()" class="header" [ngStyle]="{ height: collapsed ? '100px' : '150px' }">
  <div nz-row>
    <div style="display: flex; flex-direction: row; width: 100%">
      <div class="logo">
        <app-inspection-company-logo-uploader [formControl]="logoControl" (logoSelected)="onLogoChanged($event)">
        </app-inspection-company-logo-uploader>
      </div>
      <div class="title-wrapper">
        <h5 class="header-subtitle">{{ 'Portal.InspectionCompany.HeaderSubtitle' | translate }}</h5>
        <h2 class="header-title">
          {{ inspectionCompany.name }}
          <button *ngIf="!collapsed" type="button" nz-button nzShape="circle" nzType="text" title="Edit" (click)="toggleEditCompany()">
            <mac-icon icon="edit"></mac-icon>
          </button>
        </h2>
      </div>
      <div class="title-button" (click)="toggleCollapse()">
        <mac-icon *ngIf="collapsed" icon="collapse" class="expand_more"></mac-icon>
        <mac-icon *ngIf="!collapsed" icon="collapse" class="expand_less"></mac-icon>
      </div>
    </div>
  </div>
  <div *ngIf="!collapsed" nz-row style="padding-left: 15px">
    <app-input
      [formControl]="nameControl"
      [showAsTextIfDisabled]="true"
      [name]="'name'"
      [required]="true"
      [label]="'Portal.InspectionCompany.Edit.Form.Name.Label' | translate"
      [placeholder]="'Portal.InspectionCompany.Edit.Form.Name.Placeholder' | translate">
    </app-input>
    <app-input
      [formControl]="emailControl"
      [showAsTextIfDisabled]="true"
      [name]="'email'"
      [required]="true"
      [label]="'Portal.InspectionCompany.Edit.Form.PrimaryEmail.Label' | translate"
      [placeholder]="'Portal.InspectionCompany.Edit.Form.PrimaryEmail.Placeholder' | translate">
    </app-input>
    <app-input
      [formControl]="subdomainControl"
      [showAsTextIfDisabled]="true"
      [name]="'subdomain'"
      [required]="true"
      [label]="'Portal.InspectionCompany.Edit.Form.Subdomain.Label' | translate"
      [placeholder]="'Portal.InspectionCompany.Edit.Form.Subdomain.Placeholder' | translate">
    </app-input>
    <app-select
      [options]="languages"
      [formControl]="cultureControl"
      [showAsTextIfDisabled]="true"
      [name]="'culture'"
      [required]="true"
      [label]="'Portal.InspectionCompany.Edit.Form.Culture.Label' | translate"
      [placeholder]="'Portal.InspectionCompany.Edit.Form.Culture.Placeholder' | translate">
    </app-select>
    <app-input
      [formControl]="vatNumberControl"
      [showAsTextIfDisabled]="true"
      [name]="'vatNumber'"
      [required]="true"
      [label]="'AdministrationPortal.InspectionCompany.Edit.Form.VatNumber.Label' | translate"
      [placeholder]="'AdministrationPortal.InspectionCompany.Edit.Form.VatNumber.Placeholder' | translate">
    </app-input>
    <mac-button
      type="submit"
      [disabled]="icForm.invalid || icForm.pristine"
      [isLoading]="isSavingInspectionCompanyDetails"
      *ngIf="icForm.enabled || isSavingInspectionCompanyDetails">
      {{ 'Portal.InspectionCompany.Edit.Form.Submit' | translate }}
    </mac-button>
  </div>
</form>
