<mac-button variant="text" size="small" padding="compact" [multiline]="true" nz-dropdown
  [nzDropdownMenu]="languagesSelectTemplate" nzTrigger="click">
  <div class="user-profile">
    <nz-avatar [nzText]="avatarText"></nz-avatar>
    <div class="profile">
      <div class="profile-title">{{ userAccount?.firstName }} {{ userAccount?.lastName }}</div>
      <div class="profile-subtitle">{{ subTitle }}</div>
    </div>
  </div>
</mac-button>

<nz-dropdown-menu #languagesSelectTemplate="nzDropdownMenu">
  <ul nz-menu nzSelectable>
    <li nz-menu-item routerLink="/settings"> {{ 'Shared.Settings' | translate }}</li>
    <li nz-menu-item (click)="logout()"> {{ 'Shared.Logout' | translate }}</li>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #errorLoadingLanguagesTemplate="nzDropdownMenu">
  <ul nz-menu [nzSelectable]="false">
    <li nz-menu-item [nzDisabled]="true" nzDanger>
      {{ 'Shared.SelectLanguage.Error.LoadingLanguages' | translate }}
    </li>
  </ul>
</nz-dropdown-menu>