import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NzButtonType } from 'ng-zorro-antd/button';

@Component({
  selector: 'mac-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() variant: Variant = 'primary';
  @Input() size: Size = 'large';
  @Input() icon?: string;
  @Input() iconPlacement: IconPlacement = 'left';
  @Input() type: Type = 'button';
  @Input() form: string | null = null;
  @Input() showAsBlock = false;
  @Input() isLoading: boolean = false;
  @Input() disabled: boolean | string | undefined = undefined;
  @Input() padding: Padding = 'comfortable';
  @Input() disableHoverState = false;
  @Input() multiline = false;
  /**
   * Use the button as a link.
   */
  @Input() routerLink?: string | string[];

  @Output() click: EventEmitter<void> = new EventEmitter();

  get nzButtonVariant(): NzButtonType {
    switch (this.variant) {
      case 'secondary':
      case 'tertiary':
      case 'danger':
        return null;
      default:
        return this.variant;
    }
  }

  public clickEvent() {
    this.click.emit();
  }

  protected readonly undefined = undefined;
}

export type Type = 'button' | 'submit' | 'reset';
export type Variant = 'primary' | 'secondary' | 'tertiary' | 'text' | 'danger';
export type IconPlacement = 'left' | 'right';
export type Size = 'large' | 'default' | 'small';
export type Padding = 'compact' | 'comfortable';
