<div>
  <customer-logo [logo]="logoSrc"></customer-logo>
  <nz-divider></nz-divider>
  <ng-container *ngFor="let navItem of navItems">
    <a [routerLink]="navItem.link" routerLinkActive #rla="routerLinkActive" style="cursor: auto">
      <mac-button size="small" class="nav-button" [variant]="rla.isActive ? 'primary' : 'text'" [disableHoverState]="rla.isActive">
        {{ navItem.label | translate }}
      </mac-button>
    </a>
  </ng-container>
</div>
