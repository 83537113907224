import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ModalComponent } from 'projects/component-library/components/modal/modal.component';
import { NotificationTitle } from 'projects/shared/constants/notifications/notification-message';
import { AccountService } from 'projects/shared/services/account/account.service';
import { ChangeAccountPasswordRequestModel } from 'projects/shared/services/account/models/change-account-password.model';
import { ErrorResponse, ExpectedError, HttpErrorResponse } from 'projects/shared/services/models/error.response';
import { SharedValidators } from 'projects/shared/validators/shared-validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
})
export class ChangePasswordModalComponent implements OnInit {
  @Input() isVisible = false;
  @Output() actionComplete = new EventEmitter();
  @ViewChild('modal') modal: ModalComponent;

  errors: ExpectedError[] = [];
  isSubmittingForm: boolean = false;
  form: FormGroup;
  errorMessage: string | null;
  oldPassword: FormControl<string | null> = new FormControl<string | null>(null, [Validators.required, Validators.minLength(8)]);
  newPassword: FormControl<string | null> = new FormControl<string | null>(null, [Validators.required, Validators.minLength(8)]);
  passwordConfirmation: FormControl<string | null> = new FormControl<string | null>(null, [
    Validators.required,
    SharedValidators.matchValidator(this.newPassword, 'PASSWORD_DOES_NOT_MATCH'),
  ]);
  constructor(
    private formBuilder: FormBuilder,
    private accountService: AccountService,
    private nzNotificationService: NzNotificationService,
    private translateService: TranslateService) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group<ChangePasswordForm>({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      passwordConfirmation: this.passwordConfirmation,
    });
  }

  onSubmit(): void {
    this.isSubmittingForm = true;
    const request: ChangeAccountPasswordRequestModel = {
      currentPassword: this.oldPassword.value!,
      newPassword: this.newPassword.value!,
    };
    this.accountService.changeAccountPassword(request).subscribe({
      next: () => {
        this.nzNotificationService.success(
          NotificationTitle.Success,
          this.translateService.instant('Shared.ProfileSettings.Password.Success.Message'));
        this.isSubmittingForm = false;
        this.actionComplete.emit();
      },
      error: (response: HttpErrorResponse) => {
        this.errors = this.tryHandleAsFieldError(response.error);
        this.isSubmittingForm = false;
      },
    });
  }

  private tryHandleAsFieldError(error: ErrorResponse): ExpectedError[] {
    const userPasswordErrors: ExpectedError[] = ['USER_PASSWORD_INVALID'];
    const newPasswordErrors: ExpectedError[] = [
      'USER_PASSWORD_MISSING_LOWERCASE_CHARACTER',
      'USER_PASSWORD_MISSING_UPPERCASE_CHARACTER',
      'USER_PASSWORD_MISSING_NUMBER_CHARACTER',
      'USER_PASSWORD_MISSING_SPECIAL_CHARACTER',
      'USER_PASSWORD_CONSECUTIVE_PATTERN_FOUND',
      'USER_PASSWORD_CONTAINS_NAME',
      'USER_PASSWORD_REUSED',
      'USER_PASSWORD_TOO_SHORT',
    ];
    const unhandledErrors: ExpectedError[] = [];

    for (const errorKey of error.errors) {
      if (userPasswordErrors.includes(errorKey)) {
        this.oldPassword.setErrors({ ...this.oldPassword.errors, ...{ [errorKey]: true } });
      }

      if (newPasswordErrors.includes(errorKey)) {
        this.newPassword.setErrors({ ...this.newPassword.errors, ...{ [errorKey]: true } });
      }

      unhandledErrors.push(errorKey);
    }

    return unhandledErrors;
  }

  changeVisibility(isOpen: boolean) {
    if (!isOpen) {
      this.resetAllFormGroupsAndControls();
      this.errorMessage = null;
      this.modal.closeModal();
    } else {
      this.modal.openModal();
    }
  }

  openModal(): void {
    this.modal.openModal();
  }

  resetAllFormGroupsAndControls(): void {
    this.form.reset();
    this.form.updateValueAndValidity();
    this.errors = [];
  }
}

interface ChangePasswordForm {
  oldPassword: FormControl<string | null>;
  newPassword: FormControl<string | null>;
  passwordConfirmation: FormControl<string | null>;
}
