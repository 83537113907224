import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mac-modal-cancel-button',
  template: `
    <mac-button variant="tertiary" (click)="onCancel()" [disabled]="isDisabled">
      {{ 'Shared.Modal.CancelButton.Label' | translate }}
    </mac-button>
  `,
})
export class ModalCancelButtonComponent {
  @Input() isDisabled = false;
  @Output() cancel = new EventEmitter<void>();

  public onCancel() {
    this.cancel.emit();
  }
}
