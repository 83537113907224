<nz-alert *ngIf="hasErrors" [nzType]="type" [nzMessage]="title || ''"
  [nzDescription]="isSingleError ? singleErrorTemplate : aggregateErrorTemplate" [ngStyle]="styles"></nz-alert>
<ng-template #singleErrorTemplate>
  {{ (errorKeyPrefix + keys[0]) | translate }}
</ng-template>

<ng-template #aggregateErrorTemplate>
  <ul class="list">
    <li *ngFor="let key of keys">
      {{ (errorKeyPrefix + key) | translate }}
    </li>
  </ul>
</ng-template>