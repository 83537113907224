import { Component, Input } from "@angular/core";
import { ValidationErrors } from '@angular/forms';
import { createErrorKey } from 'projects/shared/utils/getErrorMessage';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() title?: string;
  @Input() message: string;
  @Input() type: 'success' | 'info' | 'warning' | 'error' = 'info';
  @Input() errorKeys: string[] | ValidationErrors | null | undefined = [];
  @Input() styles: any = {};

  readonly errorKeyPrefix: string;

  constructor() {
    this.errorKeyPrefix = createErrorKey();
  }

  get keys(): string[] {
    if (!this.errorKeys) {
      return [];
    }

    if (this.errorKeys instanceof Array) {
      return this.errorKeys;
    }

    return Object.keys(this.errorKeys);
  }

  get hasErrors(): boolean {
    return this.keys.length > 0;
  }

  get isSingleError(): boolean {
    return this.keys.length === 1;
  }
}

export interface Error {
  message: string;
  errors: string[];
}
