import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormInputComponentBase } from 'projects/component-library/components/form-items/form-input-base.component';
import { TranslationService } from '../../../services/translations/translation-service';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectOption } from '../../../../component-library/components/input-select/select-option';

@Component({
  selector: 'app-culture-select-input',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CultureSelectInputComponent,
    },
  ],
  template: `
    <mac-form-input-select [formControl]="formControl"
       [label]="'Domain.User.Culture.Input.Label' | translate"
       [placeholder]="'Domain.User.Culture.Input.Placeholder' | translate"
       [options]="cultures">
    </mac-form-input-select>
  `,
})
export class CultureSelectInputComponent extends FormInputComponentBase implements OnInit {
  public cultures: SelectOption[] = [];
  @Output('error') httpErrors = new EventEmitter<string>();

  constructor(
    controlContainer: ControlContainer,
    private translationService: TranslationService) {
    super(controlContainer);
  }

  ngOnInit(): void {
    this.fetchCultures();
  }

  private fetchCultures() {
    this.formControl.disable();
    this.translationService.cultures$.subscribe({
      next: (culturesResponse) => {
        this.cultures = culturesResponse.sort((a, b) => a.name.localeCompare(b.name)).map((c) => ({ id: c.id, label: c.name }));
        this.formControl.enable();
      },
      error: () => {
        this.formControl.setErrors({ CouldNotLoadCultures: 'Failed to load cultures.' });
        this.httpErrors.emit('CouldNotLoadCultures');
      },
    });
  }
}
