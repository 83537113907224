import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { FormInputTextComponent } from '../form-input-text/form-input-text.component';

@Component({
  selector: 'mac-form-input-password',
  templateUrl: './form-input-password.component.html',
  styleUrls: ['../form-input-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormInputPasswordComponent
    }
  ]
})
export class FormInputPasswordComponent extends FormInputTextComponent {
  passwordVisible = false;

  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }
}
