import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isDebugEnvironment } from 'projects/shared/utils/environmentHelpers';

import { AuthorizeComponent } from '../../../shared/components/authorize/authorize.component';
import { NotFoundLayoutComponent } from '../../../shared/components/layouts/not-found-layout/not-found-layout.component';
import { UnauthorizedComponent } from '../../../shared/components/unauthorized/unauthorized.component';

const routes: Routes = [
  // Authorization component handler
  { path: 'authorize', component: AuthorizeComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },

  {
    path: isDebugEnvironment() ? ':subdomain' : '',
    loadChildren: () => import('./inspection-companies/inspection-companies.module').then((m) => m.InspectionCompanyModule)
  },
  {
    path: isDebugEnvironment() ? ':subdomain/communications' : 'communications',
    loadChildren: () => import('./communications/communications.module').then((m) => m.CommunicationsModule)
  },
  {
    path: isDebugEnvironment() ? ':subdomain/subcontractors' : 'subcontractors',
    loadChildren: () => import('./subcontractors/subcontractors.module').then((m) => m.SubcontractorsModule)
  },
  {
    path: isDebugEnvironment() ? ':subdomain/settings' : 'settings',
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: isDebugEnvironment() ? ':subdomain/roles' : 'roles',
    loadChildren: () => import('./roles/roles.module').then((m) => m.RolesModule)
  },
  // Handle all other routes
  { path: '**', component: NotFoundLayoutComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
