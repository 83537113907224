import { Component, Input, TemplateRef } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-error-tip',
  templateUrl: './error-tip.component.html'
})
export class ErrorTipComponent {
  @Input() prefixTemplate?: TemplateRef<void>;
  @Input() errors: ValidationErrors | null;
  @Input() displayAll: boolean;
  constructor() { }

  errorMessage(key: string): string {
    switch (key) {
      case 'emptyHtml': return 'Shared.Validation.Error.MailMustContainBody';
      case 'unknownParameter': return `Shared.Validation.Error.UnkownParametersUsed`;
      case 'required': return 'Shared.Validation.Error.InputIsRequired';
      default: return '';
    }
  }
}
