import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-inspection-company-logo-uploader',
  templateUrl: './logo-uploader.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InspectionCompanyLogoUploaderComponent,
    },
  ],
})
export class InspectionCompanyLogoUploaderComponent implements ControlValueAccessor {
  @ViewChild(FormControlDirective)
  formControlDirective: FormControlDirective;

  @Input() formControl: FormControl<string | null>;
  @Input() formControlName: string;

  @Output() logoSelected = new EventEmitter<File>();

  readonly width = 150;
  readonly height = 50;
  readonly acceptedContentTypes = ['image/png', 'image/jpeg'];

  constructor(
    private controlContainer: ControlContainer,
    private nzNotificationService: NzNotificationService,
    private translateService: TranslateService
  ) {}

  get control(): FormControl<any> {
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  onLogoSelected(file: File): void {
    if (file) {
      this.validate(
        file,
        () => this.onFileValid(file),
        () => this.onFileInvalid()
      );
    }
  }

  validate(file: File, onSuccess: () => void, onError: () => void): void {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setTimeout(() => {
        const { naturalWidth: width, naturalHeight: height } = img;
        window.URL.revokeObjectURL(img.src);

        width === this.width && height === this.height ? onSuccess() : onError();
      }, 100);
    };

    reader.onerror = onError;
  }

  onFileValid(file: File): void {
    this.logoSelected.emit(file);
  }

  onFileInvalid(): void {
    this.control.setErrors({ invalid: true });
    this.nzNotificationService.error(
      this.translateService.instant('AdministrationPortal.InspectionCompany.Edit.Form.Logo.Errors.InvalidImage.Title'),
      this.translateService.instant('AdministrationPortal.InspectionCompany.Edit.Form.Logo.Errors.InvalidImage.Body'),
      { nzDuration: 0 }
    );
  }

  // Since we're just passing the reactive form control to the underlying input,
  // we don't need to do implement here
  writeValue(value: any): void {}
  setDisabledState(isDisabled: boolean): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
}
