<div class="roles-and-permissions-wrapper" *ngIf="matrix">

  <div class="permissions">
    <div *ngFor="let permissionId of permissions; trackBy: trackByString" class="label item">
      <span>
        {{ 'Domain.Permission.' + permissionId + '.Label' | translate }}
        <mac-icon class="help" icon="help" nz-tooltip
          nzTooltipTitle="{{ 'Domain.Permission.' + permissionId + '.Tooltip' | translate }}"></mac-icon>
      </span>
    </div>
  </div>

  <div class="roles-wrapper">
    <div style="width: 100%;">
      <div nz-row [nzGutter]="16">
        <div nz-col style="width: 20%;" *ngFor="let roleId of roles; trackBy: trackByString">
          <app-roles-permissions-matrix-column
            [permissions]="permissions" [roleId]="roleId" [matrix]="matrix[roleId]" [loading]="loading">
          </app-roles-permissions-matrix-column>
        </div>
      </div>
    </div>
  </div>
</div>