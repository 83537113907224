import { Injectable } from "@angular/core";
import { Observable, of as observableOf } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { catchError } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { SaveCommunicationAssignmentRequest } from "./models/save-communication-assignment.model";
import { CommunicationDefinitionHeaderResponse } from "./models/communication-definition-header.response";
import { CommunicationDefinitionResponse } from "./models/communication-definition.response";
import { CommunicationAssignmentResponse } from "./models/communication-assignment.response";
import { CommunicationAssignment } from "projects/shared/models/communication-assignment";

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  private readonly httpNotFound = 404;

  constructor(private httpClient: HttpClient) { }

  private buildUrl(inspectionCompanyId?: number): string {
    let icIdentifier: string = inspectionCompanyId?.toString() ?? 'admin';
    let url = `${environment.restApiBaseUrl}/api/${icIdentifier}/communications`;

    return url
  }

  async getCommunicationDefinitionHeaders(inspectionCompanyId?: number): Promise<CommunicationDefinitionHeaderResponse[]> {
    const url = `${this.buildUrl(inspectionCompanyId)}/definitions`;
    return lastValueFrom(this.httpClient.get<CommunicationDefinitionHeaderResponse[]>(url));
  }

  getCommunicationDefinition(communicationId: string, inspectionCompanyId?: number): Observable<CommunicationDefinitionResponse> {
    const url = `${this.buildUrl(inspectionCompanyId)}/definitions/${communicationId}`;
    return this.httpClient.get<CommunicationDefinitionResponse>(url);
  }

  async getCommunications(inspectionCompanyId?: number): Promise<CommunicationAssignmentResponse[]> {
    const url = this.buildUrl(inspectionCompanyId);
    return lastValueFrom(this.httpClient.get<CommunicationAssignmentResponse[]>(url));
  }

  getCommunicationAssignment(communicationId: string, inspectionCompanyId?: number): Observable<CommunicationAssignment> {
    const url = `${this.buildUrl(inspectionCompanyId)}/${communicationId}`;
    return this.httpClient.get<CommunicationAssignment>(url).pipe(
      catchError((error: any) => {
        if (error.status === this.httpNotFound) {
          return observableOf(new CommunicationAssignment(communicationId, inspectionCompanyId));
        }
        throw error;
      })
    );
  }

  saveCommunicationAssignment(communicationAssignment: CommunicationAssignment, inspectionCompanyId?: number): Observable<CommunicationAssignment> {
    const url = `${this.buildUrl(inspectionCompanyId)}/${communicationAssignment.communicationId}`;
    const body: SaveCommunicationAssignmentRequest = {
      noCommunication: communicationAssignment.noCommunication,
      defaultCulture: communicationAssignment.defaultCulture,
      emailTemplates: communicationAssignment.emailTemplates
    };
    return this.httpClient.put<CommunicationAssignment>(url, body);
  }
}