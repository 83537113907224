import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormInputComponentBase } from '../form-input-base.component';
import { createErrorKey } from 'projects/shared/utils/getErrorMessage';

@Component({
  selector: 'mac-form-input-text',
  templateUrl: './form-input-text.component.html',
  styleUrls: ['../form-input-base.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormInputTextComponent,
    },
  ],
})
export class FormInputTextComponent extends FormInputComponentBase implements ControlValueAccessor {
  @Input() suffix?: string | TemplateRef<void>;
  @Input() addOnAfter?: string | TemplateRef<void>;


  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }

  createErrorKey(key: string): string {
    return createErrorKey(key);
  }
}
