import 'froala-editor/js/plugins.pkgd.min';

import { Component, OnInit } from '@angular/core';
import { CommunicationService } from 'projects/shared/services/communications/communication.service';
import { CommunicationDefinitionHeaderResponse } from 'projects/shared/services/communications/models/communication-definition-header.response';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnInit {
  communications: CommunicationDefinitionHeaderResponse[] = [];
  loading = true;

  constructor(private communicationService: CommunicationService) { }
  async ngOnInit(): Promise<void> {
    this.communications = await this.communicationService.getCommunicationDefinitionHeaders();
    this.loading = false;
  }
}
