import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { SelectOption } from 'projects/component-library/components/input-select/select-option';
import { NotificationTitle } from 'projects/shared/constants/notifications/notification-message';
import { InspectionCompanyService } from 'projects/shared/services/inspection-companies/inspection-company.service';
import { EditInspectionCompanyModel } from 'projects/shared/services/inspection-companies/models/edit-inspection-company.model';
import { InspectionCompanyResponse } from 'projects/shared/services/inspection-companies/models/inspection-company.response';
import { CultureResponse } from 'projects/shared/services/translations/models/culture.response';
import { TranslationService } from 'projects/shared/services/translations/translation-service';

@Component({
  selector: 'app-inspection-company-header',
  templateUrl: './inspection-company-header.component.html',
  styleUrls: ['./inspection-company-header.component.scss']
})
export class InspectionCompanyHeaderComponent {
  @Input() inspectionCompany: InspectionCompanyResponse;
  @Output() inspectionCompanyChange = new EventEmitter<InspectionCompanyResponse>();

  icForm: FormGroup;
  nameControl = new FormControl<string | null>({ value: null, disabled: true }, [Validators.required, Validators.maxLength(200)]);
  emailControl = new FormControl<string | null>({ value: null, disabled: true }, [
    Validators.required,
    Validators.maxLength(255)
  ]);
  cultureControl = new FormControl<string | null>({ value: null, disabled: true }, [
    Validators.required,
    Validators.maxLength(10)
  ]);
  subdomainControl = new FormControl<string | null>({ value: null, disabled: true }, [
    Validators.required,
    Validators.maxLength(63)
  ]);
  vatNumberControl = new FormControl<string | null>({ value: null, disabled: true }, [
    Validators.required,
    Validators.maxLength(50)
  ]);
  logoControl = new FormControl<string | null>({ value: null, disabled: true });

  collapsed = true;
  newLogo: File | undefined;
  isSavingInspectionCompanyDetails = false;
  isLoadingCultures = true;
  languages: SelectOption[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private translationService: TranslationService,
    private nzNotificationService: NzNotificationService,
    private translateService: TranslateService,
    private inspectionCompanyService: InspectionCompanyService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeInspectionCompany(this.inspectionCompany);
    this.fetchCultures();
  }

  get collapsedIcon(): string {
    return this.collapsed ? 'right' : 'down';
  }

  toggleEditCompany(): void {
    const enabled = this.icForm.enabled;
    if (enabled) {
      this.icForm.reset();
      this.initializeInspectionCompany(this.inspectionCompany);
      this.icForm.disable();
    } else {
      this.nameControl.enable();
      this.cultureControl.enable();
      this.vatNumberControl.enable();
      this.logoControl.enable();

      this.icForm.markAsTouched();
    }
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
    if (!this.collapsed && this.icForm.enabled) {
      this.toggleEditCompany();
    }
  }

  onLogoChanged(logo: File): void {
    this.newLogo = logo;
  }

  saveCompanyDetails(): void {
    this.icForm.disable();
    this.isSavingInspectionCompanyDetails = true;

    const model: EditInspectionCompanyModel = {
      name: this.nameControl.value!,
      cultureCode: this.cultureControl.value!,
      vatNumber: this.vatNumberControl.value!,
      logo: this.newLogo || undefined
    };

    this.inspectionCompanyService.editCompanyDetails(this.inspectionCompany.id, model).subscribe({
      next: (company) => {
        this.nzNotificationService.success(NotificationTitle.Success, this.translateService.instant('Shared.Success.Save'));
        this.inspectionCompanyChange.emit(company);
      },
      error: () => {
        this.isSavingInspectionCompanyDetails = false;
        this.nzNotificationService.error(
          NotificationTitle.Error,
          this.translateService.instant('Portal.InspectionCompany.Edit.Form.Error'),
          {
            nzDuration: 0
          }
        );
      },
      complete: () => {
        this.isSavingInspectionCompanyDetails = false;
      }
    });
  }

  private fetchCultures(): void {
    this.isLoadingCultures = true;
    this.translationService.cultures$.subscribe({
      next: (cultures) => {
        this.isLoadingCultures = false;
        this.initializeLanguages(cultures);
      },
      error: () => {
        this.isLoadingCultures = false;
        this.showError('Shared.Error.Generic');
      },
    });
  }

  private initializeLanguages(cultures: CultureResponse[]): void {
    this.languages = cultures.map((l) => ({
      id: l.code,
      label: l.name
    }));
  }

  private initializeForm(): void {
    this.icForm = this.formBuilder.group({
      name: this.nameControl,
      email: this.emailControl,
      culture: this.cultureControl,
      subdomain: this.subdomainControl,
      vatNumber: this.vatNumberControl,
      logo: this.logoControl
    });
  }

  private initializeInspectionCompany(inspectionCompany: InspectionCompanyResponse): void {
    this.nameControl.setValue(inspectionCompany.name);
    this.subdomainControl.setValue(inspectionCompany.subdomain);
    this.emailControl.setValue(inspectionCompany.primaryEmail);
    this.cultureControl.setValue(inspectionCompany.culture);
    this.vatNumberControl.setValue(inspectionCompany.vatNumber);
    this.logoControl.setValue(inspectionCompany.logo || null);

    this.icForm.markAsPristine();
    this.icForm.markAsUntouched();
  }

  private showError(messageKey: string) {
    this.nzNotificationService.error(NotificationTitle.Error, this.translateService.instant(messageKey), { nzDuration: 0 });
  }
}
