import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { SecurityService } from '../services/security.service';
import { isNullOrUndefined } from 'is-what';

@Injectable()
export class LoginGuard  {
  constructor(private securityService: SecurityService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let properRedirectUrl = '';
    if (!isNullOrUndefined(state) && !isNullOrUndefined(state.url)) {
      properRedirectUrl = state.url;
    }

    const result = await this.securityService.isLoggedIn();

    if (!result) {
      this.securityService.login(properRedirectUrl);
      return false;
    }
    return true;
  }
}
