import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '../components/input-select/select-option';
import { fuzzyFilterSelectOptions } from '../utils/searchHelper';

@Pipe({
  name: 'fuzzyFilter',
})
export class FuzzyFilterPipe implements PipeTransform {
  transform(value: SelectOption[], searchValue: string): SelectOption[] {
    if (!searchValue || searchValue === '') {
      return value;
    }
    return fuzzyFilterSelectOptions(value, searchValue);
  }
}
