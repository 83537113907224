<nz-card class="roles" [nzTitle]="'Domain.Role.' + roleId + '.Label' | translate">
  <div *ngFor="let permissionId of permissions; trackBy: trackByString" class="has-permission">
    <ng-container *ngIf="!loading; else loadingTemplate">
      <mac-icon
        [icon]="matrix[permissionId] ? 'check' : 'close'" [class.has-permission]="matrix[permissionId]"
        [class.no-permission]="!matrix[permissionId]"
        style="font-size: 2rem;">
      </mac-icon>
    </ng-container>
  </div>
</nz-card>

<ng-template #loadingTemplate>
  <nz-spin [nzSpinning]="loading" class="spinner"> </nz-spin>
</ng-template>