import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserWithRolesResponse } from './models/users/user-with-roles.response';
import { UsersDeletedResponse } from './models/users/users-deleted-response';
import { ReinvitePendingUsersModel } from '../invitations/models/reinvite-pending-users.model';

@Injectable({
  providedIn: 'root',
})
export class InspectionCompanyUserService {
  baseUrl: (inspectionCompanyId: number) => string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = (inspectionCompanyId) => `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyId}/users`;
  }

  inviteUsers(inspectionCompanyId: number, users: InviteUsersRequest[]): Observable<UserWithRolesResponse[]> {
    return this.httpClient.post<UserWithRolesResponse[]>(this.baseUrl(inspectionCompanyId), { users });
  }

  bulkInviteUsers(inspectionCompanyId: number, file: File): Observable<InviteBulkUsersResponse> {
    const url = `${this.baseUrl(inspectionCompanyId)}/bulkInvite`;
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<InviteBulkUsersResponse>(url, formData);
  }

  deleteUsers(inspectionCompanyId: number, request: DeleteUsersRequest): Observable<UsersDeletedResponse> {
    const options = { body: request };
    return this.httpClient.delete<UsersDeletedResponse>(this.baseUrl(inspectionCompanyId), options);
  }

  reinvitePendingUsers(inspectionCompanyId: number, model: ReinvitePendingUsersModel): Observable<void> {
    const url = `${this.baseUrl(inspectionCompanyId)}/reinvite`;
    return this.httpClient.post<void>(url, model);
  }

  updateUser(
    inspectionCompanyId: number,
    userId: number,
    model: { email: string; firstName: string; lastName: string; roles: string[]; cultureId: number },
  ): Observable<void> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyId}/users/${userId}`;
    return this.httpClient.put<void>(url, model);
  }

  bulkUpdateUsers(inspectionCompanyId: number, file: File): Observable<UpdateBulkUsersResponse> {
    const url = `${this.baseUrl(inspectionCompanyId)}/bulkUpdate`;
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post<UpdateBulkUsersResponse>(url, formData);
  }
}

export interface InviteUsersRequest {
  email: string;
  roles: string[];
}

export interface DeleteUsersRequest {
  userEmails: string[];
}

export interface InviteBulkUsersResponse {
  successCount: number;
  errorCount: number;
  errorList: UsersToInvite[];
}

export interface UsersToInvite {
  email: string;
  roles: string[];
  error: string;
}

export interface UpdateBulkUsersResponse {
  successCount: number;
  errorCount: number;
  errorList: UsersToUpdate[];
}

export interface UsersToUpdate {
  email: string;
  newRoles: string[];
  newEmail: string;
  newCulture: string;
  newFirstName: string;
  newLastName: string;
  error: string;
}
