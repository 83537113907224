import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImageUploadComponent,
    },
  ],
})
export class ImageUploadComponent implements ControlValueAccessor {
  @Input() name?: string;
  @Input() required? = false;
  @Input() formControl: FormControl<string | null>;
  @Input() formControlName: string;
  @Input() accept?: string[];
  @Input() fixedWidth: number | null = null;
  @Input() fixedHeight: number | null = null;

  @Output() fileChange = new EventEmitter<File>();

  @ViewChild(FormControlDirective, { static: true })
  formControlDirective: FormControlDirective;

  constructor(private controlContainer: ControlContainer) {}

  get control(): FormControl<any> {
    return this.formControl || this.controlContainer?.control?.get(this.formControlName);
  }

  onChange!: (_: any) => void;
  onTouched!: () => void;

  beforeUpload = (nzFile: NzUploadFile, _fileList: NzUploadFile[]): boolean => {
    const file = nzFile as unknown as File;
    if (file) {
      this.toBase64(file, (base64File: string) => {
        // Show the updated file in the UI.
        this.onChange(base64File);

        // Notify the parent component that the file has changed.
        this.fileChange.emit(file);
      });
    } else {
      this.onChange(null);
    }

    // Must return false to prevent file upload using ngZorro.
    return false;
  };

  action(): string { return ''; }

  private toBase64(file: File, callback: (result: string) => void): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result!.toString());
  }

  /**
   * Write form value to the DOM element (model => view)
   */
  writeValue(value: any): void {
    this.formControlDirective?.valueAccessor?.writeValue(value);
  }

  /**
   * Write form disabled state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.formControlDirective?.valueAccessor?.setDisabledState!(isDisabled);
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnChange(fn);
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    this.formControlDirective?.valueAccessor?.registerOnTouched(fn);
    this.onTouched = fn;
  }
}
