import { Component } from '@angular/core';

@Component({
  selector: 'mac-modal-footer',
  template: ` <div class="modal-footer"><ng-content></ng-content></div>`,
  styles: [
    `
      .modal-footer {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
      }
    `,
  ],
})
export class ModalFooterComponent {}
