import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectOption } from 'projects/component-library/components/input-select/select-option';
import { ChangePasswordModalComponent } from '../change-password-modal/change-password-modal.component';
import { AccountResponseModel } from 'projects/shared/services/account/models/account.model';

@Component({
  selector: 'app-user-settings-form',
  templateUrl: './user-settings-form.component.html',
  styleUrls: ['./user-settings-form.component.scss'],
})
export class UserSettingsFormComponent implements OnInit {
  @Input() cultures: SelectOption[];

  form: FormGroup<UserSettingsForm>;
  changePasswordModalVisible = false;

  constructor(private formBuilder: FormBuilder) {}
  emailControl = new FormControl<string | null>(null, [Validators.required, Validators.email, Validators.maxLength(255)]);
  firstNameControl = new FormControl<string | null>(null, [Validators.required, Validators.maxLength(255)]);
  lastNameControl = new FormControl<string | null>(null, [Validators.required, Validators.maxLength(255)]);
  cultureControl = new FormControl<number | null>(null, [Validators.required]);
  passwordControl = new FormControl<string | null>(null, [Validators.required]);

  @ViewChild(ChangePasswordModalComponent) ChangePasswordModal: ChangePasswordModalComponent;

  ngOnInit(): void {
    this.form = this.formBuilder.group<UserSettingsForm>({
      email: this.emailControl,
      firstName: this.firstNameControl,
      lastName: this.lastNameControl,
      culture: this.cultureControl,
      password: this.passwordControl,
    });
  }

  fillUserForm(userInfo: AccountResponseModel): void {
    const passwordPlaceholder = '************';
    this.emailControl.setValue(userInfo.email);
    this.firstNameControl.setValue(userInfo.firstName);
    this.lastNameControl.setValue(userInfo.lastName);
    this.cultureControl.setValue(userInfo.cultureId);
    this.passwordControl.setValue(passwordPlaceholder);
    this.passwordControl.disable();
  }

  onChangePassword(): void {
    this.ChangePasswordModal.changeVisibility(true);
  }

  onActionComplete(): void {
    this.ChangePasswordModal.changeVisibility(false);
  }
}

export interface UserSettingsForm {
  email: FormControl<string | null>;
  firstName: FormControl<string | null>;
  lastName: FormControl<string | null>;
  culture: FormControl<number | null>;
  password: FormControl<string | null>;
}
