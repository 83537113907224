import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  ActivateInspectionCompanyModel,
  RegisterUserForInspectionCompanyModel,
} from './models/activate-inspection-company.model';
import { EditInspectionCompanyModel } from './models/edit-inspection-company.model';
import { InspectionCompanyResponse } from './models/inspection-company.response';
import { InviteInspectionCompanyModel } from './models/invite-inspection-company.model';
import { PaginatedResponse } from '../models/paginated.response';
import { UserWithRolesResponse } from './models/users/user-with-roles.response';
import { GetUsersForInspectionCompanyModel } from './models/users/get-users-for-inspection-company.request';
import { toHttpParams } from 'projects/shared/utils/httpParamsHelpers';
import { InviteSubcontractorModel } from './models/invite-subcontractor.model';

@Injectable({
  providedIn: 'root'
})
export class InspectionCompanyService {
  constructor(private httpClient: HttpClient) {
  }

  getAllInspectionCompanies(): Observable<InspectionCompanyResponse[]> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies`;
    return this.httpClient.get<InspectionCompanyResponse[]>(url);
  }

  getInspectionCompanyById(inspectionCompanyId: number): Observable<InspectionCompanyResponse> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyId}`;
    return this.httpClient.get<InspectionCompanyResponse>(url);
  }

  getBySubdomain(subdomain: string): Observable<InspectionCompanyResponse> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/subdomains/${subdomain}`;
    return this.httpClient.get<InspectionCompanyResponse>(url);
  }

  invite(model: InviteInspectionCompanyModel): Observable<InspectionCompanyResponse> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/invite`;
    return this.httpClient.post<InspectionCompanyResponse>(url, model);
  }

  inviteSubcontractor(inspectionCompanyId: number, model: InviteSubcontractorModel): Observable<void> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyId}/subcontractors`;
    return this.httpClient.post<void>(url, model);
  }

  activate(inspectionCompanyId: number, model: ActivateInspectionCompanyModel): Observable<InspectionCompanyResponse> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyId}/activate`;
    return this.httpClient.post<InspectionCompanyResponse>(url, model);
  }

  registerUserAndActivate(
    inspectionCompanyId: number,
    model: RegisterUserAndActivateInspectionCompanyModel
  ): Observable<InspectionCompanyResponse> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyId}/register-user-and-activate-inspection-company`;
    return this.httpClient.post<InspectionCompanyResponse>(url, model);
  }

  editCompanyDetails(inspectionCompanyId: number, model: EditInspectionCompanyModel): Observable<InspectionCompanyResponse> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyId}`;

    const body = new FormData();
    body.append('name', model.name);
    body.append('cultureCode', model.cultureCode);
    body.append('vatNumber', model.vatNumber);

    if (model.logo) {
      body.append('logo', model.logo);
    }

    return this.httpClient.put<InspectionCompanyResponse>(url, body);
  }

  getUsers(
    inspectionCompanyId: number,
    filterCriteria: GetUsersForInspectionCompanyModel
  ): Observable<PaginatedResponse<UserWithRolesResponse>> {
    const params = toHttpParams(Object.entries(filterCriteria));
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/${inspectionCompanyId}/users`;
    return this.httpClient.get<PaginatedResponse<UserWithRolesResponse>>(url, { params });
  }

  getInspectionCompanySubcontractors(inspectionCompanyReference: string): Observable<InspectionCompanyResponse[]> {
    const url = `${environment.restApiBaseUrl}/api/inspectioncompanies/references/${inspectionCompanyReference}/subcontractors`;
    return this.httpClient.get<InspectionCompanyResponse[]>(url);
  }
}

export type RegisterUserAndActivateInspectionCompanyModel = ActivateInspectionCompanyModel &
  RegisterUserForInspectionCompanyModel;
