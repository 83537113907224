//Modules
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AntdModule } from './antd.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ComponentLibraryModule } from 'projects/component-library/component-library.module';

//Components
import { AuthorizeComponent } from './components/authorize/authorize.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { BlankLayoutComponent } from './components/layouts/blank-layout/blank-layout.component';
import { NotFoundLayoutComponent } from './components/layouts/not-found-layout/not-found-layout.component';
import { InputComponent } from './components/forms/input/input.component';
import { ImageUploadComponent } from './components/forms/image-upload/image-upload.component';
import { DividerComponent } from './components/divider/divider.component';
import { SelectComponent } from './components/forms/select/select.component';
import { AlertComponent } from './components/alert/alert.component';
import { ErrorTipComponent } from './components/error-tip/error-tip.component';
import { InspectionCompanyLogoUploaderComponent } from './components/inspection-companies/logo-uploader/logo-uploader.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageHeaderActionsComponent } from './components/page-header/page-header-actions/page-header-actions.component';
import { CommunicationsComponent } from './components/communications/communications.component';
import { CommunicationContentComponent } from './components/communications/communication-content/communication-content.component';
import { CommunicationFormComponent } from './components/communications/communication-content/communication-form/communication-form.component';
import { InspectionCompanyHeaderComponent } from './components/inspection-companies/inspection-company-header/inspection-company-header.component';
import { BaseHeaderComponent } from './components/header/base-header.component';
import { FormItemDateComponent } from './components/form-items/form-item-date/form-item-date.component';
import { UserProfileButtonComponent } from './components/settings/user-profile-button/user-profile-button.component';
import { UserSettingsFormComponent } from './components/settings/user-settings-form/user-settings-form.component';
import { ChangePasswordModalComponent } from './components/settings/change-password-modal/change-password-modal.component';
import { SettingNavBarComponent } from './components/setting-nav-bar/setting-nav-bar.component';
import { LogoComponent } from './components/logo/logo.component';
import { LanguageButtonComponent } from './components/language-button/language-button.component';
import { CustomerLogoComponent } from './components/customer-logo/customer-logo.component';
import { CultureSelectInputComponent } from './components/forms/culture-select-input/culture-select-input.component';
import { RolesSelectInputComponent } from './components/forms/roles-select-input/roles-select-input.component';
import { RolesPermissionsMatrixComponent } from './roles/components/roles-permissions-matrix/roles-permissions-matrix.component';
import { RolesInfoPageComponent } from './roles/pages/info/roles-info.page';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SidebarActionsComponent } from './components/sidebar/sidebar-actions.component';
import { RolesPermissionsMatrixColumnComponent } from './roles/components/roles-permissions-matrix/column/roles-permissions-matrix-column.component';

//Services
import { SecurityService } from './services/security.service';
import { LanguageService } from './services/language.service';
import { RoleService } from './services/roles/role.service';
import { InspectionCompanyUserService } from './services/inspection-companies/inspection-company.user.service';
import { InspectionCompanyCustomerContractService } from './services/inspection-companies/inspection-company.customer-contract.service';
import { AccountService } from './services/account/account.service';

//Guards
import { LoginGuard } from './guards/login.guard';
import { PermissionGuard } from './guards/permission.guard';

//Pipes
import { FormControlPipe } from './pipes/form-control.pipe';

//Directives
import { HasPermissionDirective } from '../shared/directives/has-permission.directive';
import { ForceTanComponent } from './components/table-items/force-tan/force-tan.component';
import { CustomerCompanyStatusComponent } from './components/table-items/customer-company-status/customer-company-status.component';

const pages = [AuthorizeComponent, UnauthorizedComponent];
const layoutComponents = [BlankLayoutComponent, NotFoundLayoutComponent];
const components = [
  AlertComponent,
  BaseHeaderComponent,
  ChangePasswordModalComponent,
  CommunicationContentComponent,
  CommunicationFormComponent,
  CommunicationsComponent,
  CultureSelectInputComponent,
  CustomerLogoComponent,
  DividerComponent,
  ErrorTipComponent,
  FormItemDateComponent,
  ImageUploadComponent,
  InputComponent,
  InspectionCompanyHeaderComponent,
  InspectionCompanyLogoUploaderComponent,
  LanguageButtonComponent,
  LogoComponent,
  PageHeaderActionsComponent,
  PageHeaderComponent,
  RolesInfoPageComponent,
  RolesPermissionsMatrixColumnComponent,
  RolesPermissionsMatrixComponent,
  RolesSelectInputComponent,
  SelectComponent,
  SettingNavBarComponent,
  SidebarComponent,
  SidebarActionsComponent,
  UserProfileButtonComponent,
  UserSettingsFormComponent,
  ForceTanComponent,
  CustomerCompanyStatusComponent
];
const pipes = [FormControlPipe];
const directives = [HasPermissionDirective];
const services = [
  AccountService,
  InspectionCompanyCustomerContractService,
  InspectionCompanyUserService,
  LanguageService,
  RoleService,
  SecurityService,
];
const guards = [LoginGuard, PermissionGuard];

@NgModule({
  declarations: [...pages, ...layoutComponents, ...components, ...pipes, ...directives],
  imports: [
    AntdModule,
    CommonModule,
    RouterModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentLibraryModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgOptimizedImage,
  ],
  exports: [
    AntdModule,
    TranslateModule,
    ReactiveFormsModule,
    ComponentLibraryModule,
    FroalaEditorModule,
    FroalaViewModule,
    ...layoutComponents,
    ...components,
    ...pipes,
    ...directives
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...services, ...guards]
    };
  }
}
