import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { FormInputComponentBase } from '../form-input-base.component';

@Component({
  selector: 'mac-form-input-file',
  templateUrl: './form-input-file.component.html',
  styleUrls: ['./form-input-file.component.scss', '../form-input-base.component.scss'],
})
export class FormInputFileComponent extends FormInputComponentBase {
  @Input() accept?: string[];
  @Input() fixedWidth: number | null = null;
  @Input() fixedHeight: number | null = null;
  @Input() uploadedImgSrc: string | null = null;
  @Input() isLoading: boolean | null = false;
  @Input() fileType: string | null = 'image';
  imgSrc: string | null = null;

  get overlayIcon(): string {

    switch (this.fileType) {
      case 'file':
        return 'attach_file_add';
      default:
        return 'photo_camera';
    }

  }

  @Output() fileChange = new EventEmitter<File>();

  constructor(controlContainer: ControlContainer) {
    super(controlContainer);
  }

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;

    const files = target.files;
    if (files === null) {
      return;
    }
    const file = files[0];
    this.handleFile(file);
  }

  handleFile(file: File): void {
    if (file) {
      this.toBase64(file, (base64File: string) => {
        // Show the updated file in the UI.
        if (this.fileType != 'file') {
          this.imgSrc = base64File;
        }


        // Notify the parent component that the file has changed.
        this.fileChange.emit(file);
      });
    }
  }

  action(): string {
    return '';
  }

  private toBase64(file: File, callback: (result: string) => void): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result!.toString());
  }

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.handleFile(files[0]);
    }
  }

}
