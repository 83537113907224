import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from 'is-what';
import { Observable, Subject } from 'rxjs';
import { DefaultCultureCode } from '../../shared/constants/cultures';
import { LocalizationObject, TranslatableObject } from '../models/localization-objects';

@Injectable()
export class LanguageService {
  private readonly defaultCultureCode = DefaultCultureCode;
  private languageSource = new Subject<string>();
  private preferredLanguage: string;
  languageObservable: Observable<string>;

  constructor(private translateService: TranslateService) {
    this.languageObservable = this.languageSource.asObservable();
  }

  setPreferredLanguage(language: string): void {
    if (!isNullOrUndefined(this.preferredLanguage) && this.preferredLanguage != language) {
      this.translateService.use(language);
      this.languageSource.next(language);
      this.setLanguage(language);
    }
    this.preferredLanguage = language;
  }

  getPreferredLanguage(): string {
    return this.getLanguage();
  }

  translate(localizations: LocalizationObject[]): string {
    if (!localizations) {
      return '';
    }

    const translation = localizations.find((item) => item.cultureName === this.preferredLanguage);
    const englishTranslation = localizations.find((item) => item.cultureName === this.defaultCultureCode);
    if (translation && translation.value) {
      return translation.value;
    }

    if (englishTranslation) {
      return englishTranslation.value;
    }

    return '';
  }

  sortAlphabetically(left: TranslatableObject, right: TranslatableObject) {
    const leftText = this.translate(left.translations);
    const rightText = this.translate(right.translations);

    if (leftText < rightText) {
      return -1;
    }
    if (leftText > rightText) {
      return 1;
    }
    return 0;
  }

  private setLanguage(language: string): void {
    localStorage.setItem('language', language);
  }

  private getLanguage(): string {
    return localStorage.getItem('language')?.toString() ?? this.defaultCultureCode;
  }
}
