import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { redirectWithSubdomainPrefixOnLocalhost } from 'projects/shared/utils/subdomainHelper';
import { Subscription, take } from 'rxjs';
import { LanguageService } from 'projects/shared/services/language.service';

@Component({
  selector: 'portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Macadam.InspectionCompanies.Portal';
  translationsLoaded = false;
  languageLoader$: Subscription;
  routerEventsSubscription$: Subscription;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private languageService: LanguageService) {
    this.initializeLanguage();
    this.handleSubdomainRoutes();
  }

  ngOnInit(): void {
    this.languageLoader$ = this.translate.onDefaultLangChange
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.translationsLoaded = true;
        },
      });
  }

  ngOnDestroy(): void {
    this.languageLoader$.unsubscribe();
    this.routerEventsSubscription$.unsubscribe();
  }

  private initializeLanguage(): void {
    this.translate.setDefaultLang('en');

    const language = this.languageService.getPreferredLanguage();
    this.translate.use(language);
  }

  private handleSubdomainRoutes(): void {
    this.routerEventsSubscription$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        redirectWithSubdomainPrefixOnLocalhost(this.router, event);
      }
    });
  }
}
