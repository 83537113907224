import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationStart,
  Router,
} from '@angular/router';
import { isDebugEnvironment } from './environmentHelpers';

/**
 * When using [routerLink], we don't want to prefix the subdomain since this only happens locally.
 * This function will prefix the subdomain when the user navigates to a different url.
 */
export const redirectWithSubdomainPrefixOnLocalhost = (router: Router, event: NavigationStart): void => {
  if (isDebugEnvironment() && router.url !== '/') {
    const currentUrlSubdomain = getSubdomain(router.url);
    if (getSubdomain(event.url) !== currentUrlSubdomain) {
      router.navigate([`/${currentUrlSubdomain}${event.url}`]);
    }
  }
};

export const getSubdomainFromRoute = (route: ActivatedRoute | ActivatedRouteSnapshot): string => {
  let subdomain = '';

  if (route instanceof ActivatedRouteSnapshot) {
    subdomain = route.params['subdomain'];
  } else if (route instanceof ActivatedRoute) {
    subdomain = route.snapshot.params['subdomain'];
  }

  return subdomain ?? window.location.host.split('.')[0];
};

const getSubdomain = (url: string): string => {
  const parts = url.split('/').filter((x) => !!x);
  if (parts.length === 0) {
    return '';
  }
  return parts[0];
};
