import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-roles-permissions-matrix-column',
  templateUrl: './roles-permissions-matrix-column.component.html',
  styleUrls: ['./roles-permissions-matrix-column.component.scss'],
})
export class RolesPermissionsMatrixColumnComponent {
  @Input() roleId: string;
  @Input() permissions: string[] = [];
  @Input() matrix: Record<string, boolean> = {};
  @Input() loading = false;

  trackByString(_index: number, item: string): string {
    return item;
  }
}
