import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'mac-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit {
  @Input() type: 'text' | 'email' | 'password' = 'text';
  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() control: FormControl<string | null>;
  @Input() disabled: boolean = false;
  @Input() icon?: string;
  @Input() suffix?: string | TemplateRef<void>;
  @Input() addSuffixContent?: boolean = false;
  @Input() prefix?: string;
  @Input() addOnAfter?: string | TemplateRef<void>;

  ngOnInit(): void {
    if (!this.control && this.disabled) {
      this.control = new FormControl<string>('');
      this.control.disable();
    }
  }
}
