import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NotificationContent } from 'projects/shared/constants/notifications/notification-content';
import { NotificationTitle } from 'projects/shared/constants/notifications/notification-message';
import { AccountService } from 'projects/shared/services/account/account.service';
import { AccountResponseModel } from 'projects/shared/services/account/models/account.model';
import { InspectionCompanyResponse } from 'projects/shared/services/inspection-companies/models/inspection-company.response';
import { SecurityService } from 'projects/shared/services/security.service';

@Component({
  selector: 'app-user-profile-button',
  templateUrl: './user-profile-button.component.html',
  styleUrls: ['./user-profile-button.component.scss']
})
export class UserProfileButtonComponent implements OnInit {
  @Input() subTitle: string;
  userAccount: AccountResponseModel | null;
  inspectionCompany: InspectionCompanyResponse;
  avatarText: string;

  constructor(private securityService: SecurityService, private accountService: AccountService, private nzNotificationService: NzNotificationService) {}

  ngOnInit(): void {
    this.accountService.getAccount().subscribe({
      next: (account) => {
        this.userAccount = account;
        this.avatarText = this.userAccount!.firstName[0] + this.userAccount!.lastName[0];
      },
      error: (err) => {
        this.nzNotificationService.error(NotificationTitle.Error, err?.error?.message || NotificationContent.DefaultError, {
          nzDuration: 0
        });
      }
    });
  }

  logout(): void {
    this.securityService.logout();
  }
}
